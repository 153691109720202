import { alert, confirm } from "@mobiscroll/react";
import { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import { setLicenseKey } from "../../../auth/core/_requests";
import FilterTooltip from "../FilterTooltip";

type Props = {
    data?: Array<any>;
    isLoading: boolean;
    showdropdown: string;
    title: string;
    currentClick: string;
    inputText: string;
    checked_ids: Array<any>;
    imgpath: string;
    hideSelection?: (e: any) => void | undefined
    storeFilterValue?: (e: any) => void | undefined
    handleChange?: (e: any) => void | undefined
    inputHandler?: (e: any) => void | undefined
    selectAll?: (e: any) => void | undefined
    selectedName: any
    tooltipTitle: any
    display_title?: any
    isOpen:any,
    toggleDropdown:any
};
const CapacityDropDown: FC<Props> = ({ data, isLoading, showdropdown, hideSelection, title, storeFilterValue, handleChange, checked_ids, inputHandler, inputText, imgpath, selectedName, selectAll, tooltipTitle, display_title, isOpen, toggleDropdown }) => {
    
    const closeDropdown = (e: any) => {
        storeFilterValue(e.target.value)
        toggleDropdown(e.target.value,"no")
    }
    const filteredData = data?.filter((el: any) => {
        //if no input the return the original
        if (inputText === '') {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.name.toLowerCase().includes(inputText)
        }
    })

    const handleLockLicense = async (res: string) => {
        confirm({
            title:
            "You don't have a license. Do you want to send a license request?",
            okText: "Yes",
            cancelText: "No",
            callback: async function (event) {
            if (event) {
                try {
                    await setLicenseKey(res);
                    alert({message: "License Request Sent!"});
                } catch (error) {
                    alert({
                        message: "Error Sending License Request', 'An error occurred while sending the license request."
                      });
                }
            }
            },
        });

    };

    const emptyIdIsChecked = title === "cPeople" ? filteredData?.length + 1 : filteredData?.length;
    const titleMap = {
        'People': 'Resources',
        'servicecall_status': 'Servicecall status',
        'projectlead': 'Project leads',
        'queues': 'Queues',
        'LineOfBusiness': 'Line of business'
      };
    
    return (
        <>
            <div className={`dropDown me-4 mb-2 ${showdropdown}`}>
                <>
                    <button onClick={() => toggleDropdown(title)} id={`${title}-CID`} className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-0 h-32px fs-16 d-inline-flex align-items-center position-relative mainTootltiphover" type="button" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-start" data-kt-menu-flip="bottom">
                        <KTSVG path={imgpath} className='me-2' svgClassName="w-auto h-auto mw-14px mh-14px" stylecode={{ color: "inherit" }} />
                        <span>{titleMap[display_title] || display_title}</span> &nbsp;
                        {checked_ids.length > 0 && <span className="badge rounded-pill badge-primary bg-yellow text-dark fs-16 px-3 top-0 ms-1 d-inline-block min-h-24px min-w-24px">+{checked_ids.length}</span>}

                        <div className="closeDiv ms-2 me-n4 triggerTooltip position-relative">
                            <button value={title} onClick={hideSelection} className='p-0 bg-transparent border-0 w-20px h-auto'>
                                <KTSVG path="/media/icons/duotune/extra/close.svg" className='text-primary pe-none' svgClassName="w-10px h-auto" />
                            </button>
                            {/* Code start - Set this in component */}
                            <FilterTooltip />
                            {/* Code end */}
                        </div>

                        <div className="tooltip bs-tooltip-bottom cusTooltip top-100 start-50 translate-middle-x">
                            <div className="tooltip-arrow position-absolute start-50 translate-middle-x"></div>
                            <div className="tooltip-inner min-w-200px mh-400px overflow-hidden">{selectedName?.length > 0 ? (selectedName?.join(', ')) : (tooltipTitle)}
                            </div>
                        </div>

                    </button>
                    {isOpen &&
                        <div className={`menu menu-sub menu-sub-dropdown bg-white px-0 pt-3 pb-0 w-160px show`} style={{ animation: "none", position: 'absolute' }} data-kt-menu="true">
                            <div className="searchBox d-flex align-items-center border-bottom mb-3 px-3">
                                <KTSVG path="/media/icons/duotune/extra/search.svg" className='me-2 opacity-50' svgClassName="w-12px h-12px" stylecode={{ color: "#292D32" }} />
                                <input type="text" value={inputText} className="form-control form-control-flush p-0 fw-normal fs-14 h-30px pe-2" id={`${title}-csearch`} name="search" onChange={inputHandler} placeholder="Search..." autoComplete="off" ></input>
                                <div className="form-check form-check-custom d-flex align-items-center justify-content-between">

                                    {filteredData && filteredData?.length > 0 &&
                                        <input className="form-check-input h-15px w-15px" style={{ borderRadius: "5px" }} type="checkbox" value="" id={title} onChange={selectAll} checked={checked_ids?.length !== 0 && filteredData?.length !== 0 && checked_ids?.length === emptyIdIsChecked} />
                                    }
                                </div>
                            </div>

                            {!isLoading && filteredData !== undefined ?
                                (<div className="chkWrapper px-3 fs-14 mh-210px overflow-scroll">
                                    <form >
                                        {title === "cPeople" ? 
                                            <div className="form-check form-check-custom d-flex align-items-center justify-content-between mb-3">
                                                <div className="d-inline-flex align-items-center">
                                                <label htmlFor="emptyCheckbox" className="form-check-label fw-semibold text-black ms-0 pe-2 d-inline-flex align-items-center">
                                                    <span>Empty</span>
                                                </label>
                                                </div>
                                                <input className={`form-check-input h-15px w-15px checkBoxClass`} style={{ borderRadius: "5px" }} type="checkbox" onChange={handleChange} checked={checked_ids.includes('noresource')} id='emptyCheckbox' data-id='cPeople' value='noresource' />
                                            </div>
                                            : ''
                                        }
                                        {Array.from(filteredData).map((e: any, key) => {
                                            let keylabel = (Math.random() + 1).toString(36).substring(7);
                                            return <div key={key} className="form-check form-check-custom d-flex align-items-center justify-content-between mb-3">
                                                <div className="d-inline-flex align-items-center">
                                                    {(title === 'cprojectlead' || title === 'cPeople') &&
                                                        (
                                                            (e?.has_license === false && e?.resource_only === false) ?
                                                                <button
                                                                    className={`bg-transparent border-0 p-0 text-ellipsis min-w-14px me-2`}
                                                                    onClick={() => handleLockLicense(e.database_id)}
                                                                >
                                                                    <KTSVG
                                                                        path='/media/icons/duotune/new-icons/lock-icon.svg'
                                                                        className="text-dark min-w-14px d-flex align-items-center justify-content-center cursor-pointer"
                                                                        svgClassName="w-14px h-auto" />
                                                                </button>
                                                                : ''
                                                        )}
                                                    <label htmlFor={keylabel} className={`form-check-label fw-semibold text-black ms-0 pe-2 d-inline-flex align-items-center ${(title === 'cprojectlead' || title === 'cPeople') && ((e?.has_license === false && e?.resource_only === false) ? 'isoffline pe-none' : '')}`}>
                                                        <span>{e.name} </span>
                                                    </label>
                                                </div>
                                                <input className={`form-check-input h-15px w-15px checkBoxClass`} style={{ borderRadius: "5px" }} type="checkbox" checked={checked_ids.includes(e.id)} onChange={handleChange} id={keylabel} data-id={title} value={`${e.id}`} name={e.name} />
                                            </div>;
                                        })}
                                    </form>
                                </div>) : (<div>
                                    <span className="ms-7">No data avaialble</span></div>)
                            }
                            <div className="w-100">
                                {checked_ids.length === 0 ? (<OverlayTrigger
                                    key="copy-to-clipboard"
                                    placement="bottom"
                                    trigger={['hover', 'focus']}
                                    overlay={<Tooltip id="tooltip-copy-to-clipboard" className="position-absolute"><span className="pe-2">Please select at least one item</span></Tooltip>}
                                >
                                    <button type="button" value={title} className="btn w-100 border-gray-101 p-3 fs-14" >Done</button>
                                </OverlayTrigger>) : (<button type="button" value={title} onClick={closeDropdown} className="btn w-100 fs-14 border-gray-101 p-3 btn-active-primary rounded-4px" >Done</button>)
                                }
                            </div>
                        </div>
                    }
                </>
            </div>
        </>
    )
};
export { CapacityDropDown };

