import React from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { Datepicker } from "@mobiscroll/react";
import moment from "moment-timezone";

const BulkSettingPopup = ({
  showstartendcomponent,
  dayRange,
  saveChanges,
  setSelectedOption,
  selectedOption
}: any) => {

  const dateKey = dayRange === "Start" ? "startDate" : "endDate";
  const handleOptionChange = (event) => {
    let newValue = JSON.parse(JSON.stringify(selectedOption));
    if(dayRange == "Start"){
      newValue['startDate']['type'] = event?.target?.value
    }else{
      newValue['endDate']['type'] = event?.target?.value
    }
    setSelectedOption(newValue)
  };

  const changeStartDate = async (e: any) => {
    let newValue = JSON.parse(JSON.stringify(selectedOption));
    let start = moment(e.value).format("YYYY-MM-DD")
    if(dayRange == "Start"){
      newValue['startDate']['date'] = start
    }else{
      newValue['endDate']['date'] = start
    }
    setSelectedOption(newValue)
  }

  const changeOffset = async (e: any) => {
    let newValue = JSON.parse(JSON.stringify(selectedOption));
    if(dayRange == "Start"){
      newValue['startDate']['offset'] = e?.target?.value
    }else{
      newValue['endDate']['offset'] = e?.target?.value
    }
    setSelectedOption(newValue)
  }

  
  const changeOffsetType = async (e: any) => {
    let newValue = JSON.parse(JSON.stringify(selectedOption));
    if(dayRange == "Start"){
      newValue['startDate']['offsetType'] = e?.target?.value
    }else{
      newValue['endDate']['offsetType'] = e?.target?.value
    }
    setSelectedOption(newValue)
  }
  return (
    <div
      className="date_editSidebar position-fixed end-0 top-0 bottom-0 start-0"
      style={{ zIndex: "999", backgroundColor: `rgba(9, 10, 17, 0.16)`}}
    >
      <div
        className="d-flex flex-column bg-white h-100 shadow paddLR24 bg-white ms-auto"
        style={{ borderColor: "#DADCE0", width: '540px' }}
      >
        {/* Toolbar */}
          <div className="d-flex align-items-center justify-content-between w-100 grayBorder min-h-65px mb-5">
            <h3 className="text-dark fw-bold fs-20px m-0 d-inline-flex align-items-center">
              Change {dayRange} date
            </h3>
            <div
              className="cursor-pointer"
              id="kt_todo_plan_close"
              onClick={()=>showstartendcomponent("")}
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/cross-circle-dark.svg"
                className="w-24px h-24px d-inline-flex"
                svgClassName="w-100 h-100"
                stylecode={{ color: "#181833" }}
              />
            </div>
          </div>
          
          {/* Radio button start */}
          <div className="d-flex align-items-center mb-12">

            <div className="form-check form-check-outline-primary form-check-custom form-check-solid h-30px fs-16 me-15">
              <input type="radio" className="form-check-input me-2" name="" id="by-offset" value="offset" checked={selectedOption?.[dateKey]?.['type'] === "offset"} onChange={handleOptionChange} />
              <label htmlFor="by-amount">By amount</label>
            </div>

            <div className="form-check form-check-outline-primary form-check-custom form-check-solid h-30px fs-16">
              <input type="radio" className="form-check-input me-2" name="" id="by-specific-date" value="specific-date" checked={selectedOption?.[dateKey]?.['type'] === "specific-date"}   onChange={handleOptionChange} />
              <label htmlFor="by-specific-date">Specific date</label>
            </div>            

          </div>
          {/* Radio button end */}

          {/* Select boxes start */}
          {
            selectedOption?.[dateKey]?.['type'] === "offset" ? (
              <>
              <div className="mb-12">
              <label className="col-form-label required fw-bold fs-16 text-dark p-0 pb-1">Adjustment type</label>
              <input type="number" id="offset-type" required onChange={changeOffset} value={selectedOption?.[dateKey]?.['offset']} className="form-select fs-16 fw-normal h-32px p-0 px-3 bg-transparent border-graybor text-dark"></input>
            </div>
  
            <div>
              <label className="col-form-label required fw-bold fs-16 text-dark p-0 pb-1">How many [days/weeks/months] do you want to change?</label>
              <select name="offset" id="offset" onChange={changeOffsetType} defaultValue={selectedOption?.[dateKey]?.['offsettype']} className="form-select fs-16 fw-normal h-32px p-0 px-3 bg-transparent border-graybor text-dark">
                <option value="day">Days</option>
                <option value="week">Weeks</option>
                <option value="month">Months</option>
              </select>
            </div> 
            </>
            ) : null
          }

          { 
            selectedOption?.[dateKey]?.['type'] === "specific-date" ? (
              <><div className="mb-8">
                  <label className="col-form-label required fw-bold fs-16 text-dark p-0 pb-1">Date</label>
                  <span
                    id="servicecall-start"
                    className="align-items-center text-nowrap position-relative border-graybor"
                  >
                    <Datepicker
                      controls={["calendar"]}
                      touchUi={true}
                      display="anchored"
                      showOverlay={false}
                      onChange={changeStartDate}
                      dateFormat="DD-MM-YYYY"
                      buttons={[]}
                      value={selectedOption?.[dateKey]?.['date'] && moment(selectedOption?.[dateKey]?.['date']).format("YYYY-MM-DD")}
                    />
                    <KTSVG
                      path="/media/icons/duotune/new-icons/down-arrow.svg"
                      className="m-0 position-absolute end-0 top-0 bottom-0 pe-none w-12px d-inline-flex align-items-center justify-content-center"
                      svgClassName="w-12px h-auto"
                    />
                  </span>
                </div>
            </>
            ) : null } 

         
          {/* Select boxes end */}

          <button type="button" 
            className="btn btn-primary h-55px py-0 px-5 rounded-8px fs-20px fw-bold w-100 mt-auto mb-8" 
            disabled={!((selectedOption?.[dateKey]?.['type'] === "offset" && selectedOption?.[dateKey]?.['offset'] !== "") || (selectedOption?.[dateKey]?.['type'] === "specific-date" && selectedOption?.[dateKey]?.['date'])) ? true : false}
            onClick={(e) =>{
              if((selectedOption?.[dateKey]?.['type'] === "offset" && selectedOption?.[dateKey]?.['offset'] !== "") || (selectedOption?.[dateKey]?.['type'] === "specific-date" && selectedOption?.[dateKey]?.['date'])){
                saveChanges(e)
              }
              }}>Continue
          </button>                 
 
      </div>
    </div>
  );
};

export default BulkSettingPopup;
