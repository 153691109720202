import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import CustomButtonComponent from "./CustomButtonComponent";
import ImageRender from "./ImageRender";
import { useQuery } from "react-query";
import { getProjectBreakdown, projectCopyPhase, projectCopyTaskTicket, projectDeletePhase, projectDeleteTask } from "../../../../../_metronic/requests/PlanningRequest";
import { confirm } from "@mobiscroll/react";

// Registering the necessary modules
ModuleRegistry.registerModules([ClientSideRowModelModule, ColumnsToolPanelModule, MenuModule, RowGroupingModule]);
type Props = {
  projectId?: number;
  isRefetchTable?: Boolean;
  tasks?: any;
  phases?: any;
  setIsFormDirty?: any
  setTasks?: any
  setPhases?: any,
  setIsCreatePhaseOpen?: any
  setIsCreateTaskOpen?: any
  handleEditTaskPopup?: any
  handleEditPhasePopup?: any
  setIsRefatchTable?: any
  setPhaseId?: any
  setDisabled?:any
}
const ProjectTable: FC<Props> = ({ setDisabled, projectId, setIsRefatchTable, isRefetchTable, setTasks, setPhases, setIsCreatePhaseOpen, setIsCreateTaskOpen, setPhaseId, setIsFormDirty, handleEditPhasePopup, handleEditTaskPopup }) => {
  // enables pagination in the grid
  const pagination = true;
  // sets 10 rows per page (default is 100)
  const paginationPageSize = 10;
  // allows the user to select the page size from a predefined list of page sizes
  const paginationPageSizeSelector = [5, 10, 20, 50, 100];
  const { data: rowData, isLoading, isFetching, refetch } = useQuery(
    "project-breakdown",
    () => {
      return getProjectBreakdown(projectId);
    },
    { cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false, refetchOnMount: true }
  );

  useEffect(() => {
    if (isRefetchTable) {
      refetch();
      setIsRefatchTable(false);
    }
  }, [isRefetchTable, refetch, setIsRefatchTable]);

    //set the inital data in the api 
      useEffect(() => {
        if (rowData?.data?.data) {
          const newTasks = Array.isArray(rowData.data.data)
            ? rowData.data.data.filter((item) => item.type === "task")
            : [];
          const newPhases = Array.isArray(rowData.data.data)
            ? rowData.data.data.filter((item) => item.type === "phase")
            : [];
          setTasks(newTasks);
          setPhases(newPhases);
        }
      }, [rowData, setTasks, setPhases]);

      useEffect(() => {
        if(isLoading){
          setDisabled(true)}
          else{
            setDisabled(false)
          }
      }, [isLoading])
  const CheckboxHeader = (props) => {
    const onCheckboxChange = (e) => {
      props.api.forEachNode(node => node.setSelected(e.target.checked));
    };

    return (
      <div className="custom-checkbox-header" style={{ display: 'flex', alignItems: 'center' }}>
        <i className="fa fa-bars" style={{ marginRight: '8px', cursor: 'move' }}></i>
        <input type="checkbox" style={{ verticalAlign: 'middle' }} onChange={onCheckboxChange} />
      </div>
    );
  };

  // Custom group row renderer to display additional data
  const CustomGroupRowRenderer = (params) => {
    const { node } = params;
    let rowData = node ? node.data : null;
    let id = rowData ? rowData.autotask_id : null;
    let type = rowData ? rowData.type : null;
    let filePath = rowData ? rowData.filePath : [];
    const currentFilePathName = filePath.length > 0 ? filePath[filePath.length - 1] : 'N/A';
    if(type === "phase"){
      id = rowData ? rowData.id : null;
    }
    return <div className="d-flex align-items-center justify-content-start">
      <button className="border-0 p-0 bg-transparent buttn-link" title={currentFilePathName} onClick={(e) => handleEditClick(params, id, type, currentFilePathName)} >
        <span>{params.value}</span>
      </button>
    </div>;
  };

  const handleCopy = async (rowData, id, type, name) => {
    confirm({
      title: `Are you sure you want to copy this ${name}?`,
      okText: 'Yes',
      cancelText: 'No',
      callback: async function (result) {
        if (result) {
          try {
            if (type === 'task') {
              const response = await projectCopyTaskTicket(id);
              const { data: { data } } = response;
              setIsFormDirty(true);
              setTasks((prevTasks) => [...prevTasks, data[0]]);
              setIsRefatchTable(true);
            } else if (type === 'phase' || rowData.type === 'subphase' || rowData.type === 'subphase1') {
              const response = await projectCopyPhase(id);
              const { data: { data } } = response;
              setTasks((prevTasks) => [...prevTasks, ...data.tasks]);
              setPhases((prevPhases) => [...prevPhases, ...data.phases]);
              setIsFormDirty(true);
              setIsRefatchTable(true);
            } else {
              console.log('Copy action not applicable for this type.');
            }
          } catch (error) {
            console.error('Error copying task/ticket:', error);
          }
        }
      }
    });

  };

  const handleCreate = async (rowData, id, type) => {
    setPhaseId(id);
    if (type === 'phase') {
      setIsCreatePhaseOpen(true);
    }
    else if (type === 'subphase') {
      setIsCreateTaskOpen(true);
    }
  };
  const handleDelete = (rowData, type, id, timeEntryCount = 0) => {
    let message = "";
    if (type === 'phase') {
      message = "If you want to delete the tasks beneith that phase. If pressed yes, then those tasks can be deleted. Else the tasks will just be directly beneith the project (other main phase if the sub phase was deleted)";
    } else if (type === 'task') {
      if (timeEntryCount > 0) {
        message = "It can’t be deleted because there are time entries on the task"
      } else {
        message = "Are you sure you want to delete this task?"
      }
    }
    confirm({
      title: message,
      okText: 'Yes',
      cancelText: 'No',
      callback: async function (result) {
        try {
          let success = true;
          if (type === "phase") {
            let response = await projectDeletePhase({ phaseID: id, isDeleteTask: result, isDeleteServicecall: result });
            if (response) success = true;
          } else if (type === "task" && timeEntryCount < 1) {
            let response = await projectDeleteTask({ taskID: id, isDeleteServicecall: result });
            if (response) success = true;
          }
          if (success) setIsRefatchTable(true);
        } catch (error) {
          console.error('Error Deleting task/ticket:', error);
        }
      }
    })

  };
  const handleEditClick = async (rowData, id, type, name) => {

    if (type === 'task') {
      console.log("In task");
      handleEditTaskPopup(id);
    } else if (type === 'phase') {
      handleEditPhasePopup(id);
    } else {
      console.log('Copy action not applicable for this type.');
    }


  };	
  const [columnDefs] = useState([
    { field: 'filePath', rowGroup: true, hide: true },
    { field: 'category', headerName: 'Category' },
    { field: 'type', headerName: 'Type', hide: true },
    { field: 'task_type', headerName: 'Type' },
    { field: 'start_date', headerName: 'Start Date' },
    { field: 'end_date', headerName: 'End Date' },
    { field: 'priority', headerName: 'Priority' },
    { field: 'primary', headerName: 'Primary resource', cellRenderer: ImageRender, minWidth: 100, cellClass: "logoCell" },
    { field: 'secondary', headerName: 'Secondary resource', cellRenderer: ImageRender, minWidth: 100, cellClass: "logoCell" },
    { field: 'status', headerName: 'Status' },
    { field: 'work_type', headerName: 'Work Type' },
    { field: 'issue', headerName: 'Issue', hide: true },
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'phaseID', headerName: 'Phase ID', hide: true },
    { field: '_id', headerName: 'Task ID', hide: true },
    { field: 'autotask_id', headerName: 'Autotask Id', hide: true },
    { field: 'isDraft', headerName: 'Is Draft', hide: true },
    { field: 'timeEntryCount', headerName: 'Time Entry count', hide: true },
    { field: 'phaseCount', headerName: 'Phase Count', hide: true },
    {
      field: 'actions',
      headerName: 'Actions',
      maxWidth: 96,
      minWidth: 96,
      sortable: false,
      cellRenderer: CustomButtonComponent,
      pinned: 'right',
      suppressNavigable: true,
      suppressMenu: true,
      suppressMovable: true,
      cellClass: 'not-selectable-cell',
      cellRendererParams: {
        onCopy: handleCopy,
        onCreate: handleCreate,
        OnDelete: handleDelete,
      },
    },
  ]);

  const defaultColDef = {
    flex: 1,
    sortable: true,
    editable: false,
    filter: true,
    floatingFilter: true,
    suppressMenu: true,
    resizable: false,
  };

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'Phase / Task / Ticket',
      minWidth: 200,
      headerComponentFramework: CheckboxHeader,
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      // cellRenderer: CustomLinkComponent,
      pinned: 'left',
      width: 'auto',
      suppressMovable: true,
      cellClass: "suppress-movable-col",
      cellRendererParams: {
        suppressCount: true, // This hides the count in the default group cell renderer
        innerRenderer: CustomGroupRowRenderer,
        onEdit: handleEditClick
      },
    };
  }, []);

  const getRowHeight = (params) => {
    return 48; // Default height
  };
  const gridOptions = {
    groupDisplayType: 'singleColumn',
    suppressCellFocus: true
  };

  const getRowId = (params) => {
    if (params.data && params.node) {
      return `${params.data.id || params.data._id}-${params.node.rowIndex}`;
    }
    // Fallback or default value, just in case
    return null;
  };

  const getRowClass = (params) => {
    return (params.data && params.data.type && params.data.type === "task") ? "row-task" : "row-phase";
  };

  const getDataPath = useCallback((data) => {
    return data.filePath || [`${data.type}-${data.id}`];
  }, []);

  return (
    <div className="agTable w-100">
      <div className="ag-theme-quartz w-100">
        {(isLoading || isFetching) ? (<h1><center>Loading...</center></h1>) :
          (!isLoading && rowData?.data?.data?.length > 0) ? (<AgGridReact
            rowData={rowData?.data?.data}
            treeData={true}
            groupDefaultExpanded={-1}
            getDataPath={getDataPath}
            columnDefs={columnDefs}
            domLayout={"autoHeight"}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            rowSelection={"multiple"}
            rowDragManaged={false}
            getRowHeight={getRowHeight}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            gridOptions={gridOptions}
            getRowId={getRowId}
            groupAggFiltering={true}
            getRowClass={getRowClass}  // Assign the getRowClass function here
          // onGridReady={onGridReady}
          />) :
            (<center><h4>No data is available for this project</h4></center>)}
      </div>
    </div>
  );
}

export default ProjectTable;
