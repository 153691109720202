import moment from "moment-timezone";
import React, { FC, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { KTSVG } from "../../../../../_metronic/helpers";
import { AUTOTASK_ENTITY } from "../../../../constants/global";
import { HoverOverTooltip } from "../../planning/components/HoverOverTooltip";
import { handleEntered, handleMouseEnter } from "../../../../helpers/servicecall";

const MyScheduleEvent: FC<any> = ({
  data,
  task_status,
  worktype,
  general_settings,
  onEventClick,
  timezone,
  view,
  todo_status_serviceCall,
  viewSettings,
  locationShow,
  locations,
  prioritylist,
  lineofbusinessList,
  projectTypeList,
  resourceList,
  companyList,
  multiSelectEvents,
  multiselectEnable
}) => {
  let ticketDiv1 = "#DFDEFF";
  let taskDiv1 = "#FFE2E2";
  let servicecallDiv1 = "#e7f6e7";
  let appointmentDiv1 = "#fffaa0";
  let companyColour = "#FFFFFF";
  const ev = data?.original;

  let colordiv1 = companyColour;
  if (ev.type === "servicecall") {
    colordiv1 = servicecallDiv1;
  } else if ((ev.parentType || ev.type) === "ticket") {
    colordiv1 = ticketDiv1;
  } else if ((ev.parentType || ev.type) === "task") {
    let selected = multiSelectEvents?.filter((i) => (i.id == ev.id && i.type === "task"))
    if(selected?.length > 0){
      colordiv1 = '#eacece';
    }else{
      colordiv1 = taskDiv1;
    }
  } else if ((ev.parentType || ev.type) === "appointment") {
    colordiv1 = appointmentDiv1;
  }

  let actual_worked = ((ev?.hoursWorked) / (ev?.estimatedHours)) * 100;
  let planned_hours = (ev?.plannedHours / ev?.estimatedHours) * 100;
  let hour_left = (ev?.hoursLeft / ev?.estimatedHours) * 100;
  if ((ev.parentType || ev.type) === "task") {
    let status_name = task_status?.filter(
      (status: any) => parseInt(status.id) == parseInt(ev.status)
    );
    ev.status_name = status_name ? status_name[0]?.label : "-";
  } else if (ev?.type === "servicecall" || ev?.type === "serviceCall") {
    ev.status_name = todo_status_serviceCall?.filter(
      (status: any) => parseInt(status.id) == parseInt(ev?.servicecall_status)
    )?.[0]?.name;
  } else {
    ev.status_name = ev?.statusName || ""
  }

  let work_type = worktype?.filter(
    (w: any) => parseInt(w.value) == parseInt(ev?.billingCodeID)
  );
  ev.worktype = work_type ? work_type[0]?.label : "-";
  ev.tooltiptitle =
    (ev?.editable !== false || ev?.type === "appointment" || ev?.type === "project" || ev?.type === "company" || ev?.type === "phase")
      ? (general_settings?.show_task_type === true
      ? ev?.type === "servicecall" || ev?.type === "serviceCall"
        ? AUTOTASK_ENTITY.ServiceCall.title + `: ` + ev?.title
        : ev?.type?.charAt(0).toUpperCase() +
        ev?.type?.slice(1) +
        ": " +
        ev?.title
      : ev?.title) +
    ", " +
    data.start +
    " - " +
    data.end
      : "This " +
      ev?.type +
      " will be disabled until it refreshes the data from Autotask.";

  let title =
    (general_settings?.show_task_type === true)
      ? (ev?.type === "servicecall" || ev?.type === "serviceCall")
        ? AUTOTASK_ENTITY.ServiceCall.title + `: ` + ev?.title
        : ev?.type?.charAt(0).toUpperCase() +
        ev?.type?.slice(1) +
        ": " +
        (ev?.title ? ev?.title : "")
      : (ev?.title ? ev?.title : "");

  const getIcon = (type: any) => {
    if (type === "company") {
      return (
        <KTSVG
          path="/media/icons/duotune/new-icons/timline-company-icon.svg"
          className="me-4px"
          svgClassName="w-auto h-auto"
        />
      )
    } else if (type === "project") {
      return (
        <KTSVG
          path="/media/icons/duotune/new-icons/timline-project-icon.svg"
          className="me-4px"
          svgClassName="w-auto h-auto"
        />
      )
    } else if (type === "phase") {
      return (
        <KTSVG
          path="/media/icons/duotune/new-icons/timline-phase-icon.svg"
          className="me-4px"
          svgClassName="w-auto h-auto"
        />
      )
    } else {
      return "";
    }
  };
  const [locationName, setLocationName] = useState(null);

   useEffect(() => {
    function findLocationName(ev, locations) {
      if (!Array.isArray(locations) || locations.length === 0) {
        return null;
      }
      if (typeof ev.companyLocationID === 'undefined' || ev.companyLocationID === null) {
        return null;
      }

      for (const location of locations) {
        if (typeof location.autotask_id !== 'undefined' && location.autotask_id !== null) {
          if (ev.companyLocationID.toString() === location.autotask_id.toString()) {
            return location.name;
          }
        }
      }
      return null;
    }
    if (ev && locations && locations?.data) {
      const name = findLocationName(ev, locations?.data);
      if (name) {
        setLocationName(name);
      } else {
        setLocationName(null); 
      }
    } else {
      setLocationName(null); 
    }
  }, [ev, locations]); 

    let Priority = null;
    if(ev?.type !== "servicecall"){
      const taskPriority = parseInt(ev?.priority); 
      Priority = prioritylist?.find((twork: any) =>{
      return parseInt(twork.value) === Number(taskPriority)        
      })
      Priority = Priority ? Priority?.label : null;
    }else{
      if (ev?.related_tasks?.length === 1) {
        const taskPriority = parseInt(ev.related_tasks[0]?.priority); 
        Priority = prioritylist?.find((twork: any) =>{
        return parseInt(twork.value) === Number(taskPriority)        
        })
        Priority = Priority ? Priority?.label : null;
      }
  
      if (ev?.related_tickets?.length === 1) {
        const ticketPriority = parseInt(ev.related_tickets[0]?.priority); 
        Priority = prioritylist?.find((twork: any) =>{
        return parseInt(twork.value) === Number(ticketPriority)        
        })
        Priority = Priority ? Priority?.label : null;
      }
    }
  
  ev.company_name = companyList?.filter((i: any) => parseInt(i.id) == parseInt(ev?.companyID))?.[0]?.name;

  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });  
  useEffect(() => {
    // Function to handle mouse movement
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };
    // Attach event listener to capture mouse position
    window.addEventListener('mousemove', handleMouseMove);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);


  const [rectValue, setRect] = useState<{ x: number; y: number; z: number }>({ x: 0, y: 0, z: 0 });
  
  return (
    <>
      <div
        className={`${ev?.type === "servicecall" ? "hash-serviceCall" : ""} ${viewSettings?.rowSelection?.row1 ? "showRow1" : "hideRow1"
          } ${viewSettings?.rowSelection?.row2 ? "showRow2" : "hideRow2"} ${viewSettings?.rowSelection?.row3 ? "showRow3" : "hideRow3"
          }`}
      >
        <OverlayTrigger
          key="copy-to-clipboard"
          placement="bottom"
          trigger={(ev?.type !== "company" && ev?.from !== "doubleClick") ? ['focus','hover'] : []}
          onEntered={(e:any) => handleEntered(e,rectValue,mousePos)}
          flip
          // defaultShow= {true}                    
          overlay={
            <Tooltip
              id="tooltip-copy-to-clipboard"
              className="position-fixed newTooltip pronewTooltip"
            >
             <><div className="text-start">
              <HoverOverTooltip
                ev = {ev}
                data ={data}
                title = {title}
                Status ={ev?.status_name}
                timezone = {timezone}
                actual_worked = {actual_worked}
                planned_hours ={planned_hours}
                hour_left = {hour_left}
                Priority = {Priority}
                task_status = {task_status}
                screen_name = "project"
                locationName={locationName}
                locationShow={locationShow}
                lineofbusinessList={lineofbusinessList}
                projectTypeList={projectTypeList}
                resourceList={resourceList} 
              />
              </div>
              </> 
            </Tooltip>
          }
        >
        <div
          onMouseEnter={(e) => handleMouseEnter(e, false,setRect)}
          onClick={(e) => {
            const ctrlPressed = e.ctrlKey;
            const shiftPressed = e.shiftKey;
            if ((!ctrlPressed && !shiftPressed) || !multiselectEnable) {
              onEventClick(e, ev)
            }
          }}
          className={`md-timeline-template-event mbsc-ios mbsc-ltr mbsc-timeline-event mbsc-timeline-event-start mbsc-timeline-event-end p-0 w-100 rounded-4px bg-white position-relative 
            ${ev?.type === "appointment" || ev?.type === "company"  ? "cursor-default" : "cursor-pointer" } 
          `}
        >
          <div
            className={`position-absolute start-0 w-100 top-0 h-32px headerColor ${ev?.type === "servicecall" && ""
              }`}
            style={{ background: colordiv1, zIndex: "-1" }}
          ></div>

          <div className="mbsc-schedule-event-inner m-0 p-0 fs-16 text-dark">
            {/* Row one start */}
            <div
              className={`h-32px overflow-hidden px-3 d-flex justify-content-between rowOne`}
            >
              <span
                className={`text-ellipsis d-inline-block align-middle h-32px mw-100 fw-bold lh-32px text-dark 
                  ${ev?.type === "company" || ev?.type === "appointment" ? "cursor-default" : "cursor-pointer" }
                `}
              >
                {getIcon(ev?.type)}
                {title ? title : ""} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>

              {ev?.status_name &&
                <div
                  className={`d-inline-flex align-items-center h-32px mw-100 ${ev?.type === "appointment" && "d-none"
                    }`}
                >
                  {!(ev?.status_name === "Complete" || ev?.status_name === "complete"
                  ) && (
                    <small
                      className="d-inline-block align-middle min-w-6px min-h-6px rounded-circle me-4px"
                      style={{
                          backgroundColor: `${ev?.status_name === "New"
                            ? "#5195FC"
                            : ev?.status_name === "To Reschedule"
                              ? "#5195FC"
                              : "#5195FC"
                            }`,
                        }}
                      >
                        {" "}
                      </small>
                    )}
                  {(ev?.status_name === "Complete" || ev?.status_name === "complete"
                  ) && (
                      <KTSVG
                        path="/media/icons/duotune/new-icons/check-icon.svg"
                        className="me-4px"
                        svgClassName="w-auto h-auto"
                      />
                    )}
                  <span className="d-inline-block align-middle fw-normal lh-sm text-ellipsis">
                    <small className="fs-100 text-ellipsis">{ev?.status_name}</small>
                  </span>
                </div>
              }
            </div>
            {/* Row one end */}

            {/* Row two start */}
            <div className="h-32px overflow-hidden d-flex align-items-center px-3 rowTwo" 
            >
              <div className="text-ellipsis">
                <KTSVG
                  path="/media/icons/duotune/new-icons/clock.svg"
                  className="text-dark me-4px"
                  svgClassName="w-16px h-auto mt-n2px"
                />
                <span className="me-4px">
                  {moment(ev?.start).tz(timezone).format("DD-MM-'YY")}
                </span>
                {view != "year" && (
                  <strong className="me-4px">{data.start}</strong>
                )}
                {view == "year" && (
                  <strong className="me-4px">
                    {moment(ev?.start)?.tz(timezone).format("HH:mm")}
                  </strong>
                )}
                <span className="me-4px">-</span>
                <span className="me-4px">
                  {moment(ev?.end).tz(timezone).format("DD-MM-'YY")}
                </span>

                {view != "year" && (
                  <strong className="me-4px">{data.end}</strong>
                )}
                {view == "year" && (
                  <strong className="me-4px">
                    {moment(ev?.end)?.tz(timezone).format("HH:mm")}
                  </strong>
                )}
              </div>
            </div>
            {/* Row two end */}

            {/* Row three start */}
            <div className="d-flex flex-column justify-content-between h-32px rowThree">
              <div className="h-24px overflow-hidden d-flex flex-wrap align-items-center px-3">
                {/* all the work types  */}

                {(ev?.type === "company" || ev?.type === "project") && (
                  <span className="d-inline-flex align-items-center me-5 mw-100" 
                  >
                    <small className="fs-100 text-ellipsis">
                      Revenue
                    </small>
                    &nbsp;<strong className="fs-100 text-ellipsis">{ev?.totalEstimatedRevenue || 0}</strong>
                  </span>)}

                {(ev?.type === "company" || ev?.type === "project") && (
                  <span className="d-inline-flex align-items-center me-5 mw-100" 
                  >
                    <small className="fs-100 text-ellipsis">
                      Costs
                    </small>
                    &nbsp;<strong className="fs-100 text-ellipsis">{ev?.totalEstimatedCost || 0}</strong>
                  </span>)}



                {(
                  (ev?.url && ev?.type === "appointment") ||
                  (ev?.Location && (ev?.type === "servicecall" || ev?.type === "appointment")) ||
                  (ev?.worktype && ev?.type === "task") ||
                  (ev?.companyLocationID && (ev?.type === "servicecall" || ev?.type === "appointment"))) ? (
                  <div className="d-inline-flex flex-wrap align-items-center h-24px mw-100" >
                      {(ev?.url && ev?.type === "appointment") && locationShow && (
                      <span className="d-inline-flex align-items-center h-24px me-5 mw-100">
                        <i className="d-inline-flex me-4px">
                          <KTSVG
                            path="/media/icons/duotune/new-icons/ms-teams.svg"
                            className="text-dark"
                            svgClassName="w-16px h-auto"
                          />
                        </i>
                        <small className="fs-100 text-ellipsis">
                          <a
                            className="text-ellipsis text-dark"
                            href={ev?.url}
                            target="_blank"
                          >
                            Teams link
                          </a>
                        </small>
                      </span>
                    )}

                      {ev?.companyLocationID && (ev?.type === "servicecall") && locationShow && (
                        <span className="d-inline-flex align-items-center h-24px me-5 mw-100">
                          {!!ev.serviceCallLocationVisible ? (<> <i className="d-inline-flex me-4px">
                            <KTSVG
                              path="/media/icons/duotune/new-icons/ms-teams.svg"
                              className="text-dark"
                              svgClassName="w-16px h-auto"
                            />
                          </i>
                            <small className="fs-100 text-ellipsis">
                              <a className="text-ellipsis text-dark" title={ev?.teamsLink !== "" ? "Click to open Teams link" : "Team link is not available."} href={ev?.teamsLink} target="_blank">
                                Teams link
                              </a>
                            </small> </>) : (
                            <> <i className="d-inline-flex me-4px">
                              <KTSVG
                                  path="/media/icons/duotune/new-icons/location.svg"
                                className="text-dark"
                                svgClassName="w-16px h-auto"
                              />
                            </i><small className="fs-100 text-ellipsis">{locationName}</small></>
                          )}
                        </span>
                      )}

              
                    {ev?.worktype && ev?.type === "task" && (
                      <span className="d-inline-flex align-items-center h-24px me-5 mw-100">
                        <i className="d-inline-flex me-4px">
                          <KTSVG
                            path="/media/icons/duotune/new-icons/business_center.svg"
                            className="text-dark"
                            svgClassName="w-16px h-auto"
                          />
                        </i>
                        <small className="fs-100 text-ellipsis">{ev.worktype}</small>
                      </span>
                    )}
                  </div>
                ) : null}


                {(ev?.type === "servicecall") && (
                  <div className="d-inline-flex align-items-center h-24px mw-100 me-5">
                    <span className="text-ellipsis">
                      {`${ev.related_tasks ? ev.related_tasks.length : 0} Tasks`}
                    </span>
                  </div>
                )}
                {(ev?.type === "phase") && (
                  <div className="d-inline-flex align-items-center h-24px mw-100 me-5">
                    <span className="text-ellipsis">
                      {`${ev?.totalTasks || 0} Tasks and ${ev?.totalServicecalls || 0} Service Calls`}
                    </span>
                  </div>
                )}
                {ev?.related_resources?.length > 0 &&
                  <div className="ms-auto h-24px">
                    <OverlayTrigger
                      key="copy-to-clipboard"
                      placement="auto"
                      trigger={(ev?.type === "company") ? ['focus','hover'] : []}
                      //defaultShow= {true}   //To set tooltip by default open
                      overlay={
                        <Tooltip
                          id="tooltip-copy-to-clipboard"
                          className="position-absolute assiresTooltip"
                        >
                          <div
                            className="md-tooltip-info align-left"
                            style={{ textAlign: "left", padding: "4px" }}
                          >
                            <div className={`fw-bold md-rect-bg-task pb-4`}>
                              Assigned Resources
                            </div>
                            {ev?.related_resources?.length > 0 &&
                              Array.from(ev?.related_resources)?.map(
                                (resource1: any, key: any) => {
                                  return (
                                    <>
                                      <div className="d-flex align-items-center md-tooltip-title m-0 mb-3">
                                        <strong className="fw-bold d-inline-flex align-items-center pe-3">
                                          <span
                                            className="rounded-circle bg-graydarkBg w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-bold lh-sm"
                                            style={{
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            {resource1?.firstName
                                              ?.trim()
                                              ?.substring(0, 1)}
                                            {resource1?.lastName
                                              ?.trim()
                                              ?.substring(0, 1)}
                                          </span>
                                        </strong>{" "}
                                        <span className="md-tooltip-reason md-tooltip-text">
                                          {resource1?.firstName}{" "}
                                          {resource1?.lastName}
                                        </span>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                          </div>
                        </Tooltip>
                      }
                    >
                      <div className="rounded-pill text-nowrap">
                        {ev?.related_resources?.length > 0 &&
                          Array.from(ev?.related_resources)?.map(
                            (resource: any, key: any) => {
                              return (
                                <>
                                  {key < 2 && resource?.firstName && (
                                    <span
                                    style={{                                      
                                      marginLeft: `${key > 0 ? "-4px" : "0"}`,
                                      textTransform: "uppercase",
                                    }}
                                    className="rounded-circle bg-graydarkBg w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 align-top" >

                                      {resource?.firstName
                                        ?.trim()
                                        ?.substring(0, 1)}
                                      {resource?.lastName
                                        ?.trim()
                                        ?.substring(0, 1)}
                                    </span>
                                    // </span>
                                  )}
                                </>
                              );
                            }
                          )}
                        {ev?.related_resources?.length > 2 && (
                          <span
                            className="rounded-circle bg-graydarkBg w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-bold lh-sm align-top"
                            style={{
                              marginLeft: "-4px",
                            }}
                          >
                            +{ev?.related_resources?.length - 2}
                          </span>
                        )}
                      </div>
                    </OverlayTrigger>
                  </div>
                }
              </div>

              {/* Progress div start */}
              {(ev?.type !== "servicecall" && ev?.type !== "appointment") && (
                <OverlayTrigger
                  key="copy-to-clipboard"
                  placement="auto"
                  trigger={( ev?.type === "company") ? ['focus','hover'] : []}
                  //defaultShow= {true}   To set tooltip by default open
                  overlay={
                    <Tooltip
                      id="tooltip-copy-to-clipboard"
                      className="position-absolute assiresTooltip "
                    >
                      <div
                        className="md-tooltip-info align-left"
                        style={{ textAlign: "left" }}
                      >
                        <div className="d-flex justify-content-between md-tooltip-title m-0">
                          <strong className="fw-bold d-inline-flex align-items-center">
                            {" "}
                            <small className="me-2 w-8px h-8px d-inline-block rounded-2px"></small>{" "}
                            Estimated Hours
                          </strong>{" "}
                          <span className="md-tooltip-reason md-tooltip-text">
                            {Number(
                              (
                                Math.round(ev?.estimatedHours * 100) / 100
                              ).toFixed(2)
                            )}
                            h
                          </span>
                        </div>
                        <div className="d-flex justify-content-between md-tooltip-title m-0">
                          <strong className="fw-bold d-inline-flex align-items-center">
                            {" "}
                            <small
                              className="me-2 w-8px h-8px d-inline-block rounded-2px"
                              style={{ backgroundColor: "#7EA8E7" }}
                            ></small>{" "}
                            Planned Hours
                          </strong>{" "}
                          <span className="md-tooltip-location md-tooltip-text">
                            {Number(
                              (
                                Math.round(ev?.plannedHours * 100) / 100
                              ).toFixed(2)
                            )}
                            h
                          </span>
                        </div>
                        <div className="d-flex justify-content-between md-tooltip-title m-0">
                          <strong className="fw-bold d-inline-flex align-items-center">
                            {" "}
                            <small
                              className="me-2 w-8px h-8px d-inline-block bg-red rounded-2px"
                              style={{ backgroundColor: "#164387" }}
                            ></small>{" "}
                            Actual Worked
                          </strong>{" "}
                          <span className="md-tooltip-location md-tooltip-text">
                            {Number(
                              (
                                Math.round(ev?.hoursWorked * 100) / 100
                              ).toFixed(2)
                            )}
                            h
                          </span>
                        </div>
                        <div className="d-flex justify-content-between md-tooltip-title m-0">
                          <strong className="fw-bold d-inline-flex align-items-center">
                            {" "}
                            <small
                              className="me-2 w-8px h-8px d-inline-block bg-red rounded-2px"
                              style={{ backgroundColor: "#CEECCE" }}
                            ></small>{" "}
                            Hours left
                          </strong>{" "}
                          <span className="md-tooltip-location md-tooltip-text">
                            {Number(
                              (Math.round(ev?.hoursLeft * 100) / 100).toFixed(
                                2
                              )
                            )}
                            h
                          </span>
                        </div>
                      </div>
                    </Tooltip>
                  }
                >
                  <div className={`progress h-3px rounded-2px`}>
                  {ev?.hoursLeft >= 0 && (
                      <><div
                        className="progress-bar text-dark fs-16 fw-bold h-100"
                        style={{
                          background: "#164387",
                          width: `${actual_worked}%`,
                        }}
                      ></div>
                      <div
                        className="progress-bar h-100 text-center text-dark fs-16 fw-bold"
                        style={{
                          background: "#7EA8E7",
                          width: `${planned_hours}%`,
                        }}
                      ></div>
                      <div
                        className="progress-bar text-dark fs-16 fw-bold h-100"
                        style={{
                          background: "#CEECCE",
                          width: `${hour_left}%`,
                        }}
                      ></div></>
                    )}
                    {ev?.hoursLeft < 0 && (
                      <div
                        className="progress-bar text-dark fs-16 fw-bold h-100"
                        style={{ background: "#f59393", width: `100%` }}
                      ></div>
                    )}
                  </div>
                </OverlayTrigger>
              )}


              {/* Progress div end */}
            </div>
            {/* Row three end */}
          </div>

          {/* Progress div start - This progressbar will only show when first row is visible & row 2-3 will be hidden */}
          {(ev?.type !== "servicecall" && ev?.type !== "appointment") && (


            <OverlayTrigger
              key="copy-to-clipboard"
              placement="bottom"
              trigger={( ev?.type === "company") ? ['focus','hover'] : []}
              overlay={
                <Tooltip
                  id="tooltip-copy-to-clipboard"
                  className="position-absolute assiresTooltip d-none extTooltip"
                >
                  <div
                    className="md-tooltip-info align-left"
                    style={{ textAlign: "left" }}
                  >
                    <div className="d-flex justify-content-between md-tooltip-title m-0">
                      <strong className="fw-bold d-inline-flex align-items-center">
                        {" "}
                        <small className="me-2 w-8px h-8px d-inline-block rounded-2px"></small>{" "}
                        Estimated Hours
                      </strong>{" "}
                      <span className="md-tooltip-reason md-tooltip-text">
                        {Number(
                          (
                            Math.round(ev?.estimatedHours * 100) / 100
                          ).toFixed(2)
                        )}
                        h
                      </span>
                    </div>
                    <div className="d-flex justify-content-between md-tooltip-title m-0">
                      <strong className="fw-bold d-inline-flex align-items-center">
                        {" "}
                        <small
                          className="me-2 w-8px h-8px d-inline-block rounded-2px"
                          style={{ backgroundColor: "#7EA8E7" }}
                        ></small>{" "}
                        Planned Hours
                      </strong>{" "}
                      <span className="md-tooltip-location md-tooltip-text">
                        {Number(
                          (
                            Math.round(ev?.plannedHours * 100) / 100
                          ).toFixed(2)
                        )}
                        h
                      </span>
                    </div>
                    <div className="d-flex justify-content-between md-tooltip-title m-0">
                      <strong className="fw-bold d-inline-flex align-items-center">
                        {" "}
                        <small
                          className="me-2 w-8px h-8px d-inline-block bg-red rounded-2px"
                          style={{ backgroundColor: "#164387" }}
                        ></small>{" "}
                        Actual Worked
                      </strong>{" "}
                      <span className="md-tooltip-location md-tooltip-text">
                        {Number(
                          (
                            Math.round(ev?.hoursWorked * 100) / 100
                          ).toFixed(2)
                        )}
                        h
                      </span>
                    </div>
                    <div className="d-flex justify-content-between md-tooltip-title m-0">
                      <strong className="fw-bold d-inline-flex align-items-center">
                        {" "}
                        <small
                          className="me-2 w-8px h-8px d-inline-block bg-red rounded-2px"
                          style={{ backgroundColor: "#CEECCE" }}
                        ></small>{" "}
                        Hours left
                      </strong>{" "}
                      <span className="md-tooltip-location md-tooltip-text">
                        {Number(
                          (
                            Math.round(ev?.hoursLeft * 100) / 100
                          ).toFixed(2)
                        )}
                        h
                      </span>
                    </div>
                  </div>
                </Tooltip>
              }
            >
              <div className="progress h-3px rounded-2px extraProgress cursor-pointer">
              {ev?.hoursLeft >= 0 && (
                <><div
                  className="progress-bar text-dark fs-16 fw-bold h-100"
                  style={{
                    background: "#164387",
                    width: `${actual_worked}%`,
                  }}
                ></div>
                <div
                  className="progress-bar h-100 text-center text-dark fs-16 fw-bold"
                  style={{
                    background: "#7EA8E7",
                    width: `${planned_hours}%`,
                  }}
                ></div>
                <div
                  className="progress-bar text-dark fs-16 fw-bold h-100"
                  style={{
                    background: "#CEECCE",
                    width: `${hour_left}%`,
                  }}
                ></div></>
              )}
                {ev?.hoursLeft < 0 && (
                  <div
                    className="progress-bar text-dark fs-16 fw-bold h-100"
                    style={{ background: "#f59393", width: `100%` }}
                  ></div>
                )}
              </div>
            </OverlayTrigger>

          )}
          {/* Progress div end - This progressbar will only show when first row is visible & row 2-3 will be hidden */}
        </div>
        </OverlayTrigger>
      </div>
    </>
  );
};

export default React.memo(MyScheduleEvent);
