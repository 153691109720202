import moment from "moment-timezone";
import React, { FC } from "react";

const HoverOverTooltip: FC<any> = ({
    ev,
    data,
    title,
    Status,
    timezone,
    actual_worked,
    planned_hours,
    hour_left,
    Priority,
    task_status,
    screen_name,
    locationName,
    locationShow,
    lineofbusinessList,
    projectTypeList,
    resourceList
}) => {
  return (
    <>
    <div className="text-start">
        <div className="mb-4">
            <h4 className="fs-16 mb-1 fw-bold text-dark lh-15 clamp-2-line"> {(ev?.type === "servicecall" && screen_name === "planning") ? ev?.servcallTitle : ev?.title}</h4>
            {(ev?.type !== 'phase' && ev?.type !== 'project') ? (<p className="fs-12 fw-normal lh-166 mb-0 clamp-2-line">{ev?.description}</p>) : (<p className="fs-12 fw-normal lh-166 mb-0">{ev?.company_name}</p>)}
        </div>                            

        <div className="bg-gray-100 rounded-4px p-4 fs-12 fw-normal text-dark mb-8px">
            {(Status) && ( 
                <div className="d-flex py-25px justify-content-between mb-2px">
                    <span className="d-block min-w-100px">Status</span>
                    <strong className="d-block fw-bold text-end"> {Status || '-'}</strong>
                </div> 
            )} 

            <div className="d-flex py-25px justify-content-between mb-2px">
                <span className="d-block min-w-100px">Start Date</span>
                <strong className="d-block fw-bold text-end">
                  {moment(ev?.start).tz(timezone).format("DD-MM-YYYY") || '-'}
                  {ev?.type === "servicecall" ? (data?.start ? ", " + data?.start : " -") : ''}
                </strong>
            </div>

            <div className="d-flex py-25px justify-content-between mb-2px">
                <span className="d-block min-w-100px">End Date</span>
                <strong className="d-block fw-bold text-end">{moment(ev?.end).tz(timezone).format("DD-MM-YYYY")}{ev?.type === "servicecall" ? ", "+data?.end : ''}</strong>
            </div>

            {(ev?.type === 'task' && Priority) && ( 
            <div className="d-flex py-25px justify-content-between mb-2px">
                <span className="d-block min-w-100px">Priority</span>
                <strong className="d-block fw-bold text-end">{Priority}</strong>
            </div>
            )}

            {ev?.type === 'project' &&
                <><div className="d-flex py-25px justify-content-between mb-2px">
                    <span className="d-block min-w-100px">Line of Business</span>
                    <strong className="d-block fw-bold text-end">{lineofbusinessList?.data?.filter((i) => parseInt(i.id) === parseInt(ev?.organizationalLevelAssociationID))?.[0]?.name || "-"}</strong>
                </div>

                <div className="d-flex py-25px justify-content-between mb-2px">
                    <span className="d-block min-w-100px">Project Lead</span>
                    <strong className="d-block fw-bold text-end">
                        {resourceList?.filter((i: any) => parseInt(i.id) === parseInt(ev?.projectLeadResourceID))?.[0]?.name || '-'}
                    </strong>
                </div>

                <div className="d-flex py-25px justify-content-between mb-2px">
                    <span className="d-block min-w-100px">Project Type</span>
                    <strong className="d-block fw-bold text-end">{projectTypeList?.filter((i: any) => parseInt(i.id) == parseInt(ev?.projectType))?.[0]?.name}</strong>
                </div></>
            }

            {(ev?.company_name !== "" && ev?.type !== 'phase' && ev?.type !== 'project' && ev?.type !== 'appointment') && ( 
                <div className="d-flex py-25px justify-content-between mb-2px">
                    <span className="d-block min-w-100px">Company</span>
                    <strong className="d-block fw-bold text-end">{ev?.company_name}</strong>
                </div> 
            )} 

            {(ev?.project_name !== "" && ev?.type === 'task') && ( 
            <div className="d-flex py-25px justify-content-between mb-2px">
                <span className="d-block min-w-100px">Project</span>
                <strong className="d-block fw-bold text-end">{ev?.project_name}</strong>
            </div>
            )} 

            {(ev?.companyLocationID && locationShow && locationName !== null) && (
                <div className="d-flex py-25px justify-content-between mb-2px">
                    <span className="d-block min-w-100px">Location</span>
                    {!!ev.serviceCallLocationVisible ? (
                        <strong className="d-block fw-bold text-end">Teams link</strong>
                    ) :(
                        <strong className="d-block fw-bold text-end">{locationName}</strong>
                    )}
                </div>
            ) }

            {(ev?.type === "project") && (
            <div className="d-flex align-items-center justify-content-between lh-166">
                <span className="d-block min-w-100px">Progress</span>
                <div className="d-flex align-items-center">
                <div className="progress h-6px rounded-pill w-90px bg-white me-4">
                    <div
                    className="progress-bar h-100 bg-lightgreen rounded-pill"
                    style={{                                        
                        width: `${ev?.completedPercentage ?? 0}%`,
                    }}
                    ></div>
                </div>                                    
                <strong className="fw-bold">{ev?.completedPercentage ?? 0}%</strong>
                </div>
            </div>   
            )}                             
            {ev?.related_resources?.length > 0 && (
                <div className="d-flex align-items-center justify-content-between lh-166">
                    <span className="d-block min-w-100px">Resources</span>
                    <div className="rounded-pill d-inline-flex align-items-center hasImgsspan lh-1">
                        {ev?.related_resources?.length === 1 ? (
                        <span className="d-flex align-items-center fw-bold">
                        {ev.related_resources[0]?.firstName}{" "}
                        {ev.related_resources[0]?.lastName}
                        </span>
                        ) : (
                        <>
                        {ev?.related_resources?.slice(0, 2).map((resource) => (
                            <span
                            key={resource?.firstName}
                            className="rounded-circle w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-bold lh-sm border"
                            style={{ borderColor: '#ECE4E4', textTransform: "capitalize", backgroundColor: '#ECE4E4' }}
                            >
                            {resource?.firstName?.trim()?.substring(0, 1)}
                            {resource?.lastName?.trim()?.substring(0, 1)}
                            </span>
                        ))}
                        {ev?.related_resources?.length > 2 && (
                            <span
                            className="rounded-circle w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-16 fw-normal lh-sm"
                            style={{ borderColor: '#ECE4E4', backgroundColor: '#ECE4E4' }}
                            >
                            +{ev.related_resources.length - 2}
                            </span>
                        )}
                        </>
                        )}
                    </div>
                </div>
            )}
        </div>

        {(ev?.related_tickets?.length > 0 || ev?.related_tasks?.length > 0) && ( 
            <>
            {(ev?.related_tickets?.length > 0 && ev?.related_tasks?.length > 0) && (
                <div className="d-flex" style={{ columnGap: '8px' }}>
                <div className="flex-fill d-flex align-items-center justify-content-between rounded-4px bg-lightpink" style={{ padding: '7px 12px' }}>
                <span className="d-block fs-12 lh-166">Tasks</span>
                <strong className="d-block fs-16 fw-bold lh-166">{ev?.related_tasks?.length}</strong>
                </div>
                <div className="flex-fill d-flex align-items-center justify-content-between rounded-4px bg-extralightblue" style={{ padding: '7px 12px' }}>
                <span className="d-block fs-12 lh-166">Tickets</span>
                <strong className="d-block fs-16 fw-bold lh-166">{ev?.related_tickets?.length}</strong>
                </div>
                </div>
            )}

            {(ev?.related_tickets?.length > 0 && ev?.related_tasks?.length === 0) && (
                <>
                    {ev?.related_tickets?.length == 1 && ( 
                        <div className="rounded-4px p-4 fs-12 fw-normal bg-extralightblue text-dark mb-8px">
                            <div className="d-flex align-items-center justify-content-between lh-166 mb-8px">                                  
                                <strong className="fw-bold clamp-2-line">{ev?.related_tickets[0]?.title}</strong>
                            </div>

                            <div className="d-flex py-25px justify-content-between mb-2px">
                                <span className="d-block min-w-100px">Status</span>
                                <strong className="d-block fw-bold text-end">{task_status?.filter((status: any) => parseInt(status.id) == parseInt(ev?.related_tickets[0]?.status))?.[0]?.name}</strong>
                            </div>

                            <div className="d-flex py-25px justify-content-between mb-2px">
                                <span className="d-block min-w-100px">Due Date</span>
                                <strong className="d-block fw-bold text-end">{ev?.related_tickets[0]?.dueDateTime ? moment(ev?.related_tickets[0]?.dueDateTime).tz(timezone).format("DD-MM-YYYY") : ""}</strong>
                            </div>

                            <div className="d-flex py-25px justify-content-between mb-2px">
                                <span className="d-block min-w-100px">Priority</span>
                                <strong className="d-block fw-bold text-end">{Priority}</strong>
                            </div>

                            {ev?.related_tickets[0]?.related_resources?.length > 0 && (
                                <div className="d-flex align-items-center justify-content-between lh-166">
                                    <span className="d-block min-w-100px">Resources</span>
                                    <div className="rounded-pill d-inline-flex align-items-center hasImgsspan lh-1">
                                        {ev?.related_tickets[0]?.related_resources?.length === 1 ? (
                                        <span className="d-flex align-items-center fw-bold">
                                        {ev.related_resources[0]?.firstName}{" "}
                                        {ev.related_resources[0]?.lastName}
                                        </span>
                                        ) : (
                                        <>
                                        {ev?.related_tickets[0]?.related_resources?.slice(0, 2).map((resource, key) => (
                                            <span
                                            key={resource?.firstName}
                                            className="rounded-circle w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-bold lh-sm border"
                                            style={{ borderColor: '#ECE4E4', textTransform: "capitalize", backgroundColor: '#ECE4E4' }}
                                            >
                                            {resource?.firstName?.trim()?.substring(0, 1)}
                                            {resource?.lastName?.trim()?.substring(0, 1)}
                                            </span>
                                        ))}
                                        {ev?.related_tickets[0]?.related_resources?.length > 2 && (
                                            <span
                                            className="rounded-circle w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-16 fw-normal lh-sm"
                                            style={{ borderColor: '#ECE4E4', backgroundColor: '#ECE4E4' }}
                                            >
                                            +{ev?.related_tickets[0]?.related_resources.length - 2}
                                            </span>
                                        )}
                                        </>
                                        )}
                                    </div>
                                </div>
                            )}   
                        </div>
                    )}

                    {ev?.related_tickets?.length > 1  && (
                        <div className="d-flex" style={{ columnGap: '8px' }}>
                            <div className="flex-fill d-flex align-items-center justify-content-between rounded-4px bg-extralightblue" style={{ padding: '7px 12px' }}>
                                <span className="d-block fs-12 lh-166">Tickets</span>
                                <strong className="d-block fs-16 fw-bold lh-166">{ev?.related_tickets?.length}</strong>
                            </div>
                        </div>
                    )}
                </> 
            )}

            {(ev?.related_tickets?.length === 0 && ev?.related_tasks?.length >= 1) && (
            <>
            {ev?.related_tasks?.length == 1 && ( 
                <div className="rounded-4px p-4 fs-12 fw-normal text-dark mb-8px bg-lightpink">
                    <div className="d-flex align-items-center justify-content-between lh-166 mb-8px">                                  
                        <strong className="fw-bold clamp-2-line">{ev?.related_tasks[0]?.title} </strong>
                    </div>
                    <div className="d-flex py-25px justify-content-between mb-2px">
                        <span className="d-block min-w-100px">Status</span>
                        <strong className="d-block fw-bold text-end">{task_status?.filter((status: any) => parseInt(status.id) == parseInt(ev?.related_tasks[0]?.status))?.[0]?.name}</strong>
                    </div>

                    <div className="d-flex py-25px justify-content-between mb-2px">
                        <span className="d-block min-w-100px">Start Date</span>
                        <strong className="d-block fw-bold text-end">{moment(ev?.related_tasks[0]?.startDateTime).tz(timezone).format("DD-MM-YYYY")}</strong>
                    </div>
                    <div className="d-flex py-25px justify-content-between mb-2px">
                        <span className="d-block min-w-100px">End Date</span>
                        <strong className="d-block fw-bold text-end">{moment(ev?.related_tasks[0]?.endDateTime).tz(timezone).format("DD-MM-YYYY")}</strong>
                    </div>

                    {Priority && (
                    <div className="d-flex py-25px justify-content-between mb-2px">
                        <span className="d-block min-w-100px">Priority</span>
                        <strong className="d-block fw-bold text-end">{Priority}</strong>
                    </div> 
                    )} 

                    {(ev?.company_name !== "") && ( 
                    <div className="d-flex py-25px justify-content-between mb-2px">
                        <span className="d-block min-w-100px">Company</span>
                        <strong className="d-block fw-bold text-end">{ev?.company_name}</strong>
                    </div>
                    )}

                    {(ev?.related_tasks[0]?.project_name !== "") && ( 
                    <div className="d-flex py-25px justify-content-between mb-2px">
                        <span className="d-block min-w-100px">Project</span>
                        <strong className="d-block fw-bold text-end">{ev?.related_tasks[0]?.project_name}</strong>
                    </div>
                    )} 

                    {ev?.related_tasks[0]?.related_resources?.length > 0 && (
                        <div className="d-flex align-items-center justify-content-between lh-166">
                            <span className="d-block min-w-100px">Resources</span>
                            <div className="rounded-pill d-inline-flex align-items-center hasImgsspan lh-1">
                                {ev?.related_tasks[0]?.related_resources?.length === 1 ? (
                                <span className="d-flex align-items-center fw-bold">
                                {ev.related_resources[0]?.firstName}{" "}
                                {ev.related_resources[0]?.lastName}
                                </span>
                                ) : (
                                <>
                                {ev?.related_tasks[0]?.related_resources?.slice(0, 2).map((resource) => (
                                    <span
                                    key={resource?.resourceID}
                                    className="fs-14 rounded-circle w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-bold lh-sm border"
                                    style={{ borderColor: '#ECE4E4', textTransform: "capitalize",backgroundColor: '#ECE4E4' }}
                                    >
                                    {resource?.firstName?.trim()?.substring(0, 1)}
                                    {resource?.lastName?.trim()?.substring(0, 1)}
                                    </span>
                                ))}
                                {ev?.related_tasks[0]?.related_resources?.length > 2 && (
                                    <span
                                    className="rounded-circle w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-normal lh-sm"
                                    style={{ borderColor: '#ECE4E4', backgroundColor: '#ECE4E4' }}
                                    >
                                    +{ev?.related_tasks[0]?.related_resources?.length - 2}
                                    </span>
                                )}
                                </>
                                )}
                            </div>
                        </div>
                    )} 
                </div>
            )}

            {ev?.related_tasks?.length > 1  && (
                <div className="d-flex" style={{ columnGap: '8px' }}>
                    <div className="flex-fill d-flex align-items-center justify-content-between rounded-4px bg-lightpink" style={{ padding: '7px 12px' }}>
                        <span className="d-block fs-12 lh-166">Tasks</span>
                        <strong className="d-block fs-16 fw-bold lh-166">{ev.related_tasks.length}</strong>
                    </div>
                </div>
            )}
            </> 
            )}
            </>
        )}

        {ev?.type === "project" &&
            <div className="d-flex text-center fw-normal fs-12 text-dark mb-5" style={{ columnGap: '8px' }}>
                <div className="bg-gray-100 rounded-4px flex-1 pa-8px">
                    <span className="lh-166 d-block fw-bold">{ev?.totalEstimatedRevenue || 0}</span>  
                    <span className="lh-166 d-block">Revenue</span>  
                </div>
                <div className="bg-gray-100 rounded-4px flex-1 pa-8px">
                    <span className="lh-166 d-block fw-bold">{ev?.totalEstimatedCost || 0}</span>  
                    <span className="lh-166 d-block">Costs</span>  
                </div>
                <div className="bg-gray-100 rounded-4px flex-1 pa-8px">
                    <span className="lh-166 d-block fw-bold">{ev?.totalProfit ? Number((Math.round((((ev?.totalEstimatedRevenue - ev?.totalEstimatedCost) / ev?.totalEstimatedRevenue) * 100) * 100) / 100 ).toFixed(2)) : 0}</span>  
                    <span className="lh-166 d-block">Profit Margin</span>  
                </div>
                <div className="bg-gray-100 rounded-4px flex-1 pa-8px">
                    <span className="lh-166 d-block fw-bold">{ev?.totalProfit || 0}</span>  
                    <span className="lh-166 d-block">Profit</span>  
                </div>    
            </div> 
        }       
            {(((ev?.type === "servicecall" && (ev?.related_tasks?.length == 1 && ev?.related_tickets?.length == 0) || (ev?.related_tasks?.length == 0 && ev?.related_tickets?.length == 1)) || (ev?.type ==="phase" || ev?.type ==="task" || ev?.type === "project"))) && (
                <>
                <div className="d-flex align-items-center fs-12 text-dark mb-8px flex-wrap justify-content-between" style={{ rowGap: '0.5rem' }}>
                    <span className="lh-166 d-inline-flex align-items-center me-2">
                        <small className="me-8px pt-1px">Estimated</small>
                        <strong className="fw-bold">{Number((Math.round((ev?.estimatedHours ?? 0) * 100) / 100 ).toFixed(2))} h</strong>
                    </span>
                    <div>
                        <span className="lh-166 d-inline-flex align-items-center me-5">
                            <i className="rounded-circle min-w-8px min-h-8px bg-lightblue me-4px"></i>
                            <small className="me-4px pt-1px">Planned</small>
                            <strong className="fw-bold">{Number((Math.round((ev?.plannedHours ?? 0) * 100) / 100 ).toFixed(2))} h</strong>
                        </span>
                        <span className="lh-166 d-inline-flex align-items-center me-5">
                            <i className="rounded-circle min-w-8px min-h-8px bg-darkblue me-4px"></i>
                            <small className="me-4px pt-1px">Actual</small>
                            <strong className="fw-bold">{Number((Math.round((ev?.hoursWorked ?? 0) * 100) / 100 ).toFixed(2))} h</strong>
                        </span>
                        <span className="lh-166 d-inline-flex align-items-center">
                            <i className="rounded-circle min-w-8px min-h-8px bg-lightgreen me-4px"></i>
                            <small className="me-4px pt-1px">Left</small>
                            <strong className="fw-bold">{Number((Math.round((ev?.hoursLeft ?? 0) * 100) / 100 ).toFixed(2))} h</strong>
                        </span>  
                    </div>
                </div>
            <div className="progress h-4px rounded-2px">
                {ev?.hoursLeft >= 0 && (
                    <><div className="progress-bar h-100 bg-darkblue" style={{ width: `${actual_worked ?? 0}%`}}></div>
                    <div className="progress-bar h-100 bg-lightblue" style={{width: `${planned_hours ?? 0}%`}} ></div>
                    <div className="progress-bar h-100 bg-lightgreen" style={{ width: `${hour_left ?? 0}%`}}></div></>
                )}
                {ev?.hoursLeft < 0 && (
                    <div className="progress-bar h-100 bg-lightred" style={{ width: `100%` }} ></div>
                )}
            </div>  
            </> 
            )}                         
    </div>
    </>
  );
};

export { HoverOverTooltip };
