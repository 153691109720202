import moment from "moment-timezone";
import React, { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { updateProjectStatus, updateTaskStatus } from "../../../../../_metronic/requests/PlanningRequest";

const RenderMyResource: FC<any> = ({ resource, timezone, ticket_status, project_status,refetchAvailability, handleHideOptionalFunc,viewSettings }) => {
  const tempResources = resource?.temp_resources ?? [];
  const relatedResources = resource?.related_resources ?? [];

  const resourcesToRender = [...tempResources, ...relatedResources].filter(
    (item, index, array) => {
      // Check if the current item's resourceID is encountered before in the array
      return (
        array.findIndex(
          (otherItem) => otherItem.resourceID === item.resourceID
        ) === index
      );
    }
  );

  const actual_worked = resource?.estimatedHours ? (resource?.hoursWorked / resource?.estimatedHours) * 100 : 0;
  const planned_hours = resource?.estimatedHours ? (resource?.plannedHours / resource?.estimatedHours) * 100 : 0;
  const hour_left = resource?.estimatedHours ? (resource?.hoursLeft / resource?.estimatedHours) * 100 : 0;

  return (
    <div className="md-resource-header-template-cont">
      <div className="md-resource-header-template-name">
        <div
          className={`${resource?.cssClass ? resource?.cssClass : "md-rect-bg-task"
            }`}
        >
          <span className={`${resource?.cssClass + '-span' ? resource?.cssClass + '-span' : "md-rect-bg-task-span"
            }`}><small>{resource?.name}</small></span>
        </div>
      </div>

      {viewSettings?.columnSelection?.assigned && <div className="md-resource-header-template-seats">
        {resource?.type != "company" &&
          (resource?.temp_resources?.length > 0 ||
            resource?.related_resources?.length > 0) && (
            <OverlayTrigger
              key="copy-to-clipboard"
              placement="bottom"
              //trigger="click"
              //defaultShow= {true}   //To set tooltip by default open
              overlay={
                <Tooltip
                  className="position-absolute assiresTooltip"
                  key={(Math.random() + 1).toString(6).substring(6)}
                >
                  <div
                    className="md-tooltip-info align-left"
                    style={{ textAlign: "left", padding: "4px" }}
                  >
                    <div className={`fw-bold md-rect-bg-task pb-4`}>
                      Assigned Resources
                    </div>
                    {resourcesToRender?.map(
                      (resourceItem: any, key: number) => {
                        if (resourceItem?.firstName) {
                          const uniqueKey = `resource-${key}`;
                          return (
                            <div
                              key={uniqueKey}
                              className={`d-flex align-items-center md-tooltip-title m-0 mb-3 ${!resourceItem.isActive && "isoffline"
                                }`}
                            >
                              <strong className="fw-bold d-inline-flex align-items-center pe-3">
                                <span
                                  className="rounded-circle bg-graydarkBg w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-bold lh-sm"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {resourceItem.firstName
                                    .trim()
                                    .substring(0, 1)}
                                  {resourceItem.lastName.trim().substring(0, 1)}
                                </span>
                              </strong>
                              <span className="md-tooltip-reason md-tooltip-text">
                                {resourceItem.firstName} {resourceItem.lastName}
                              </span>
                            </div>
                          );
                        }
                        return null;
                      }
                    )}
                  </div>
                </Tooltip>
              }
            >
              <div>
                <div
                  className="rounded-pill p-2px d-inline-block"
                  style={{
                    backgroundColor: `${resource.type === "phase" ? "#ece4e4" : "#f3f7ff"
                      }`,
                  }}
                >
                  {resourcesToRender
                    ?.slice(0, 2)
                    .map((resourceIcon: any, key: number) => {
                      if (resourceIcon?.firstName) {
                        const uniqueKey = `icon-${key}`;
                        return (
                          <span
                            key={uniqueKey}
                            className={`rounded-circle bg-graydarkBg w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-bold lh-sm border-1 ${!resourceIcon.isActive && "isoffline"
                              }`}
                            style={{
                              border: `1px solid #dbe1ea`,
                              marginLeft: `${key > 0 ? "-4px" : "0"}`,
                              textTransform: "uppercase",
                            }}
                          >
                            {resourceIcon.firstName.trim().substring(0, 1)}
                            {resourceIcon.lastName.trim().substring(0, 1)}
                          </span>
                        );
                      }
                      return null;
                    })}
                  {resourcesToRender?.length > 2 && (
                    <span
                      className="rounded-circle bg-graydarkBg px-1 h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-bold lh-sm border-1"
                      style={{
                        border: `1px solid #dbe1ea`,
                        marginLeft: "-4px",
                      }}
                    >
                      + {resourcesToRender?.length - 2}
                    </span>
                  )}
                </div>
              </div>
            </OverlayTrigger>
          )}
      </div>}
      {viewSettings?.columnSelection?.date && <div className="md-resource-header-template-date fs-12 d-flex justify-content-center">
        {(resource?.type === "project" ||
          resource?.type === "phase" ||
          resource?.type === "task") && (
            <>
              {(resource?.startDateTime || resource?.start) && (
                <div>
                  <span className="fw-normal">
                    {moment(resource?.startDateTime || resource?.start)
                      .tz(timezone)
                      .format("DD-MM-'YY")}
                  </span>
                </div>
              )}

              {resource?.endDateTime ? (
                <>
                  <div>
                    &nbsp;{"-"}&nbsp;
                    <span className="fw-normal">
                      {moment(resource?.endDateTime)
                        .tz(timezone)
                        .format("DD-MM-'YY")}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    &nbsp;{"-"}&nbsp;
                    <span className="fw-normal">
                      {moment(resource?.dueDate).tz(timezone).format("DD-MM-YY")}
                    </span>
                  </div>
                </>
              )}
            </>
          )}
      </div>}

      {viewSettings?.columnSelection?.status && <div className="md-resource-header-template-status fs-12 d-flex justify-content-center">
          {resource?.type === "task" && resource?.hasOwnProperty("status") &&
              ticket_status && (
                <select
                  value={resource?.status}
                  onChange={(e:any) =>  
                    {
                      updateTaskStatus({autotask_id:resource?.id,status:e.target.value,projectID:resource?.projectID,start:resource?.start,end:resource?.end})
                      refetchAvailability()
                    }}
                  name="Status"
                  style={{ outline: 'none' }}
                  className="border-0 bg-transparent h-30px fw-normal fs-12px w-auto mw-100 text-ellipsis"
                >
                  {handleHideOptionalFunc(ticket_status, resource?.status).map((option) => (
                    <option key={option.value} value={option.value} disabled={!option.isActive}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )
          }
          {resource?.type === "project" && resource?.hasOwnProperty("status") &&
              <select
              placeholder="Status"
              name="Status"
              style={{ outline: 'none' }}
              className="border-0 bg-transparent h-30px fw-normal fs-12px w-auto mw-100 text-ellipsis"
              value={resource?.status}
              onChange={(e:any) => 
                {
                  updateProjectStatus({id:resource?.id,status:e.target.value})
                  refetchAvailability()
                } }
            > 
              {handleHideOptionalFunc(project_status, resource?.status).map((option:any) => (
                <option key={option.value} value={option.value} disabled={!option.isActive}>
                  {option.label}
                </option>
              ))} 
            </select>
          }

      </div>}

      {viewSettings?.columnSelection?.hours && <div className="md-resource-header-template-hours fs-12 d-flex justify-content-center">

        {(resource.type === "project" ||
          resource.type === "phase" ||
          resource.type === "task") && (
            <>
              <OverlayTrigger
                key="copy-to-clipboard"
                placement="bottom"
                //trigger={'click'}
                //To set tooltip by default open
                overlay={
                  <Tooltip
                    id="tooltip-copy-to-clipboard"
                    className="position-absolute assiresTooltip text-nowrap min-w-250"
                  >
                    <div
                      className="md-tooltip-info align-left"
                      style={{ textAlign: "left" }}
                    >
                      <div className="d-flex justify-content-between md-tooltip-title m-0">
                        <strong className="fw-bold d-inline-flex align-items-center">
                          {" "}
                          <small className="me-2 w-8px h-8px d-inline-block rounded-2px"></small>{" "}
                          Estimated Hours
                        </strong>{" "}
                        <span className="md-tooltip-reason md-tooltip-text">
                          {Number(
                            (
                              Math.round(resource?.estimatedHours * 100) / 100
                            ).toFixed(2)
                          )}
                          h
                        </span>
                      </div>
                      <div className="d-flex justify-content-between md-tooltip-title m-0">
                        <strong className="fw-bold d-inline-flex align-items-center">
                          {" "}
                          <small
                            className="me-2 w-8px h-8px d-inline-block rounded-2px"
                            style={{ backgroundColor: "#7EA8E7" }}
                          ></small>{" "}
                          Planned Hours
                        </strong>{" "}
                        <span className="md-tooltip-location md-tooltip-text">
                          {Number(
                            (
                              Math.round(resource?.plannedHours * 100) / 100
                            ).toFixed(2)
                          )}
                          h
                        </span>
                      </div>
                      <div className="d-flex justify-content-between md-tooltip-title m-0">
                        <strong className="fw-bold d-inline-flex align-items-center">
                          {" "}
                          <small
                            className="me-2 w-8px h-8px d-inline-block bg-red rounded-2px"
                            style={{ backgroundColor: "#164387" }}
                          ></small>{" "}
                          Actual Worked
                        </strong>{" "}
                        <span className="md-tooltip-location md-tooltip-text">
                          {Number(
                            (
                              Math.round(resource?.hoursWorked * 100) / 100
                            ).toFixed(2)
                          )}
                          h
                        </span>
                      </div>
                      <div className="d-flex justify-content-between md-tooltip-title m-0">
                        <strong className="fw-bold d-inline-flex align-items-center">
                          {" "}
                          <small
                            className="me-2 w-8px h-8px d-inline-block bg-red rounded-2px"
                            style={{ backgroundColor: "#CEECCE" }}
                          ></small>{" "}
                          Hours left
                        </strong>{" "}
                        <span className="md-tooltip-location md-tooltip-text">
                          {Number(
                            (
                              Math.round(resource?.hoursLeft * 100) / 100
                            ).toFixed(2)
                          )}
                          h
                        </span>
                      </div>
                    </div>
                  </Tooltip>
                }
              >
                <div className="progress h-12px flex-fill rounded-2px" style={{ zIndex: "9" }}>
                  <div
                    className="progress-bar text-dark fs-16 fw-bold h-200"
                    style={{
                      background: 'rgb(22, 67, 135)',
                      width: `${actual_worked}%`,
                    }}
                  ></div>
                  <div
                    className="progress-bar h-200 text-center text-dark fs-16 fw-bold"
                    style={{
                      background: 'rgb(126, 168, 231)',
                      width: `${planned_hours}%`,
                    }}
                  ></div>
                  <div
                    className="progress-bar text-dark fs-16 fw-bold h-200"
                    style={{
                      background: 'rgb(206, 236, 206)',
                      width: `${hour_left}%`,
                    }}
                  ></div>
                  {resource?.hoursLeft < 0 && (
                    <div
                      className="progress-bar text-dark fs-16 fw-bold h-200"
                      style={{ background: 'rgb(206, 236, 206)', width: `100%` }}
                    ></div>
                  )}
                </div>
              </OverlayTrigger>

            </>
          )}
      </div>}


    </div>
  );
};

export default React.memo(RenderMyResource);
