
function applyOffset (date, offset, offsetType) {
    const resultDate = new Date(date);
    const offsetValue = parseInt(offset, 10);
    switch (offsetType) {
      case 'day':
        resultDate.setUTCDate(resultDate.getUTCDate() + offsetValue);
        break;
      case 'week':
        resultDate.setUTCDate(resultDate.getUTCDate() + (offsetValue * 7));
        break;
      case 'month':
        resultDate.setUTCMonth(resultDate.getUTCMonth() + offsetValue);
        break;
      default:
        throw new Error('Unsupported offsetType');
    }
  
    return resultDate.toISOString(); // Return in ISO format
  }

  function calculateDateDifference(startDate, endDate){
    const start = new Date(startDate);
    const end = new Date(endDate);
    const differenceInTime = end.getTime() - start.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));
    return differenceInDays;
  }

  export const  calculateDateDifferenceProject = (startDate, endDate) =>{
    return calculateDateDifference(startDate, endDate);
  }
  

  export const adjustDatesBasedOnConfig = (start, end, config) => {
    const startDateConfig = config.startDate;
    const endDateConfig = config.endDate;
    let startDate = start;
    let endDate = end;
    let adjustedStartDate = start;
    let adjustedEndDate = end;
    // Apply the startDate configuration
    if(startDateConfig.isApply){
      if (startDateConfig.type === 'offset') {
        adjustedStartDate = applyOffset(startDate, startDateConfig.offset, startDateConfig.offsetType);
        adjustedEndDate = applyOffset(endDate, startDateConfig.offset, startDateConfig.offsetType);
      } else if (startDateConfig.type === 'specific-date') {
        const startTime = startDate.split('T')[1]; // Extract time portion from start date
        adjustedStartDate = startDateConfig.date + 'T' + startTime;
        const taskDurationInDays = calculateDateDifference(startDate, adjustedStartDate);
        adjustedEndDate = applyOffset(endDate, taskDurationInDays, 'day');
      }
    }
  
    // Apply the endDate configuration
    if(endDateConfig.isApply){
      if (endDateConfig.type === 'offset') {
        adjustedStartDate = applyOffset(adjustedStartDate, endDateConfig.offset, endDateConfig.offsetType);
        adjustedEndDate = applyOffset(adjustedEndDate, endDateConfig.offset, endDateConfig.offsetType);
      } else if (endDateConfig.type === 'specific-date') {
        const endTime = endDate.split('T')[1]; // Extract time portion from end date
        adjustedEndDate = endDateConfig.date + 'T' + endTime;
        const taskDurationInDays = calculateDateDifference(endDate, adjustedEndDate);
        adjustedStartDate = applyOffset(startDate, taskDurationInDays, 'day');
      }
    }
    let offset_type = "days";
    let offset_value = calculateDateDifference(startDate, adjustedStartDate)
    return {
      adjustedStartDate,
      adjustedEndDate,
      offset_value,
      offset_type
    };
  }
 