
import { confirm } from "@mobiscroll/react";

export default function showConfirmation(message: string, onConfirmReload: () => void): Promise<boolean> {
    return new Promise((resolve) => {
        confirm({
            title: message,
            okText: 'Yes',
            cancelText: 'No',
            callback: async function (result: boolean) {
                if (result) {
                    await onConfirmReload();
                    resolve(result); // Resolve the promise with the user's decision
                }
            }
        });
    });
}