import axios, { AxiosResponse } from "axios";
import { Resource } from "../../app/modules/apps/planning/core/resourceModel";
import { Response } from "../helpers";


const API_URL_NODE = process.env.REACT_APP_API_URL;
const RESOURCE_URL_NODE = `${API_URL_NODE}`;
const SUPER_ADMIN_NODE = `${API_URL_NODE}superadmin/settings/`

const getResources = async (query: any): Promise<Resource | undefined> => {
    let sortdata = [];
    let returndata: any = [];
    let data = await axios
        .post(`${RESOURCE_URL_NODE}resources`, query)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
    if (query.sort_field == 'people_asc') {
        sortdata = data?.sort(function (a: any, b: any) { return a.name > b.name ? 1 : -1; });
    } else if (query.sort_field == 'people_desc') {
        sortdata = data?.sort(function (a: any, b: any) { return a.name < b.name ? 1 : -1; });
    } else {
        return data;
    }
    return returndata.data = sortdata;
};

const getResourceAvailability = async (cache: any, query: any, initial_sync: any): Promise<any | undefined> => {
    if (initial_sync) {
        cache = false;
        const { companies, lineOfBusiness, projects, queues_ids, servicecall_status_ids, type_ids, ...payloadQuery } = query
        const payloadData = payloadQuery
        let data = await axios
            .post(`${RESOURCE_URL_NODE}planning/resources`, payloadData)
            .then((response: AxiosResponse<Response<any>>) => response.data)
            .then((response: Response<any>) => response.data);
        return data;
    } else {
        return { count: 0, data: { formattedData: [], allResource: [] } };
    }
};

const getPlanningData = async (cache: any, query: any, initial_sync: any): Promise<any | undefined> => {
    if (initial_sync) {
        cache = false;
        const payloadData = query
        if (payloadData && payloadData.type_ids?.length > 0) {
            if (payloadData.type_ids?.includes('appointments') && !payloadData.type_ids?.includes('ticket') && !payloadData.type_ids?.includes('task')) {
                const data = await axios
                    .post(`${RESOURCE_URL_NODE}planning/appointments`, payloadData)
                    .then((response: AxiosResponse<Response<any>>) => response.data)
                    .then((response: Response<any>) => response.data)
                const response = {
                    formattedData: data,
                    plannedHours: {}
                }
                return response;
            } else if (!payloadData.type_ids?.includes('appointments') && (payloadData.type_ids?.includes('ticket') || payloadData.type_ids?.includes('task'))) {
                let data = await axios
                    .post(`${RESOURCE_URL_NODE}planning`, payloadData)
                    .then((response: AxiosResponse<Response<any>>) => response.data)
                    .then((response: Response<any>) => response.data)
                return data;
            } else {
                return await getPlanningWithAppointment(payloadData);
            }
        } else {
            return await getPlanningWithAppointment(payloadData);
        }
    } else {
        return { count: 0, data: { formattedData: [], allResource: [] } };
    }
}

const getPlanningWithAppointment = async (payloadData: any): Promise<any | undefined> => {
    let [planningResponse, appointmentsResponse] = await Promise.all([
        axios
            .post(`${RESOURCE_URL_NODE}planning`, payloadData)
            .then((response: AxiosResponse<Response<any>>) => response.data)
            .then((response: Response<any>) => response.data),
        axios
            .post(`${RESOURCE_URL_NODE}planning/appointments`, payloadData)
            .then((response: AxiosResponse<Response<any>>) => response.data)
            .then((response: Response<any>) => response.data)
    ])
    if (planningResponse && appointmentsResponse) {
        planningResponse.formattedData = planningResponse.formattedData.concat(appointmentsResponse)
    }
    return planningResponse;
}

const getProjectTask = async (query: any): Promise<any | undefined> => {
    let data = await axios
        .post(`${RESOURCE_URL_NODE}projects/companyList`, query)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
    return data;
};

const getSidebarList = async (query: any): Promise<any | undefined> => {
    let data = await axios
        .post(`${RESOURCE_URL_NODE}projects/sidebarList`, query)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
    return data;
};

const getMonthdata = async (query: any): Promise<any | undefined> => {
    const { companies, initial_sync_completed_or_not, lineOfBusiness, projects, queues_ids, servicecall_status_ids, type_ids, ...payloadQuery } = query
    if (initial_sync_completed_or_not) {
        const data = query.view_type == 'month' || query.view_type == 'year' ? payloadQuery : query
        return await axios
            .post(`${RESOURCE_URL_NODE}planning/monthyear`, data)
        // .then((response: AxiosResponse<Response<any>>) => response.data)
        // .then((response: Response<any>) => response.data);
    } else {
        return { count: 0, data: { data: [], yearData: [], monthData: [], weekData: [], dayData: [] } };
    }
};

const getResourceCapacity = async (query: any): Promise<Resource | undefined> => {
    return await axios
        .post(`${RESOURCE_URL_NODE}planning`, query)
        .then((response: AxiosResponse<Response<Resource>>) => response.data)
        .then((response: Response<Resource>) => response.data);
};
const getToPlan = async (query: any): Promise<any | undefined> => {
    return await axios
        .post(`${RESOURCE_URL_NODE}task/v2/task_screen_list`, query)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
};

const getTaskTicketList = async (query: any): Promise<any | undefined> => {
    return await axios
        .post(`${RESOURCE_URL_NODE}planning/related-items`,query)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
};

const assignServiceCallToResource = async (task: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}task/assign-service-call
            `, task).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const updatePopupvalues = async (task: any): Promise<any | undefined> => {
    //// .post(`${RESOURCE_URL_NODE}task/v2/update-popup-values
    const response = await axios
        .post(`${RESOURCE_URL_NODE}task/v2/update-popup-values
            `, task).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const createPopupvalues = async (task: any): Promise<any | undefined> => {
    //// .post(`${RESOURCE_URL_NODE}task/v2/update-popup-values
    const response = await axios
        .post(`${RESOURCE_URL_NODE}servicecall/create
            `, task).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const getTaskTicketNotes = async (task: any): Promise<any | undefined> => {
    let [notes, timeEntriesNotes] = await Promise.all([
        axios
            .post(`${RESOURCE_URL_NODE}notes/get-notes`, task)
            .then((response: AxiosResponse<Response<any>>) => response)
            .then((response: Response<any>) => response),
        axios
            .post(`${RESOURCE_URL_NODE}notes/get-timeentries`, task)
            .then((response: AxiosResponse<Response<any>>) => response)
            .then((response: Response<any>) => response)
    ])
    return { notes: notes.data, timeEntriesNotes: timeEntriesNotes.data };
};

const updateTasks = async (task: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}projects/update-task
            `, task).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};


const updateDraftPhase = async (phase: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}projects/update-phase
            `, phase).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};



const createtaskApi = async (task: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}task/create
            `, task).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const createticketApi = async (ticket: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}task/create/ticket
            `, ticket).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const deleteServicecall = async (servicecall: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}task/delete
            `, servicecall).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const spilteTasks = async (task: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}task/create-service-call
            `, task).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const assignTaskToResource = async (task: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}task/assign-task-new
            `, task).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const assignTicketToResource = async (task: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}task/assign-ticket-new`, task).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const getResourceRole = async (type: any, id: any, department_id: any): Promise<any | undefined> => {
    if (department_id === undefined) {
        department_id = '';
    }
    return await axios
        .get(`${RESOURCE_URL_NODE}resource-roles/` + type + `/` + id + `?department_id=` + department_id)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
};

const getResourcesWorkType = (query: any): Promise<any | undefined> => {
    return axios
        .get(`${RESOURCE_URL_NODE}billingcodes/worktypes`)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
};

const getPhase = (withDraft: any, projectID: any): Promise<any | undefined> => {
    return axios
        .get(`${RESOURCE_URL_NODE}masterdata/phases`, { params : {"withDraft": withDraft, projectID: projectID}} )
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
};


const getToPlanStatus = (query: any): Promise<any | undefined> => {
    return axios
        .get(`${RESOURCE_URL_NODE}task/get_all_status`)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
};

const getToPlanTaskStatusAdmin = (): Promise<any | undefined> => {
    return axios
        .get(`${SUPER_ADMIN_NODE}get_all_status/ticket`)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
};
const getToPlanTaskStatus = (): Promise<any | undefined> => {
    return axios
        .get(`${RESOURCE_URL_NODE}task/get_all_status/ticket`)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
};

const getProjectStatus = (): Promise<any | undefined> => {
    return axios
        .get(`${RESOURCE_URL_NODE}masterdata/project_statuses`)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
};

const getToPlanServiceCallStatusAdmin = async (): Promise<any | undefined> => {
    try {
        const { data } = await axios
            .get(`${SUPER_ADMIN_NODE}get_all_status/servicecall`)
        return data?.data || []
    } catch (e: any) {
        console.log(e)
    }
};

const getToPlanServiceCallStatus = async (): Promise<any | undefined> => {
    try {
        const { data } = await axios
            .get(`${RESOURCE_URL_NODE}task/get_all_status/servicecall`)
        return data?.data || []
    } catch (e: any) {
        console.log(e)
    }
};

const lastSyncData = async (query: any): Promise<any | undefined> => {
    let data = await axios
        .get(`${RESOURCE_URL_NODE}sync/get-last-sync`, query)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
    return data;
};

const saveLayoutandfilters = async (task: any, layoutName: any, layoutPageType: any, activeID: any): Promise<any | undefined> => {

    task.layoutName = layoutName;
    task.layoutPageType = layoutPageType;
    task.activeID = activeID;
    const response = await axios
        .post(`${RESOURCE_URL_NODE}masterdata/filters
            `, task).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const editLayoutandFilterName = async (layoutName: any, layoutPageType: any, layoutId: any): Promise<any | undefined> => {
    let data = {
        layoutName: layoutName,
        layoutPageType: layoutPageType,
        layoutId: layoutId
    };

    const response = await axios
        .post(`${RESOURCE_URL_NODE}masterdata/edit-filters
            `, data).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    // data.layoutName = layoutName;
    // data.layoutPageType = layoutPageType;
    // data.layoutId = layoutId;

    return response;

};

const getLayoutandFiltersList = async (query: any): Promise<any | undefined> => {

    let data = await axios
        .get(`${RESOURCE_URL_NODE}masterdata/get-all-filters/${query}`, query)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
    return data;
};




const deleteLayoutAndFilterList = async (query: any, layoutType: any): Promise<any | undefined> => {
    let data = {
        id: query,
        type: layoutType
    }
    const response = await axios
        .post(`${RESOURCE_URL_NODE}masterdata/delete-filters
            `, data).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const selectLayoutAndMakeActive = async (query: any, layoutType: any): Promise<any | undefined> => {
    let data = {
        id: query,
        type: layoutType
    }
    const response = await axios
        .post(`${RESOURCE_URL_NODE}masterdata/select-filter
            `, data).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};



const getLayoutandfilters = async (query: any): Promise<any | undefined> => {

    let data = await axios
        .post(`${RESOURCE_URL_NODE}masterdata/get-filters`, query)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
    return data;
};

const getTaskDetail = async (query: any): Promise<any | undefined> => {
    let data = await axios
        .post(`${RESOURCE_URL_NODE}projects/task`, query)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
    return data;
};

const getTaskDetailforBradkdown = async (query: any): Promise<any | undefined> => {
    let data = await axios
        .post(`${RESOURCE_URL_NODE}projects/breakdown/task`, query)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
    return data;
};

const getServicecallDetail = async (query: any): Promise<any | undefined> => {
    try {
        let { data } = await axios
            .post(`${RESOURCE_URL_NODE}projects/servicecall`, query)
        // .then((response: AxiosResponse<Response<any>>) => response.data)
        // .then((response: Response<any>) => response.data);
        return data?.data;
    } catch (e: any) {
        console.log(e.message)

    }
};

const createPhaseApi = async (task: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}projects/phase
            `, task).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const getProjectType = (query: any): Promise<any | undefined> => {
    return axios
        .get(`${RESOURCE_URL_NODE}masterdata/project_types`)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
};

const getOpportunities = (query: any): Promise<any | undefined> => {
    return axios
        .get(`${RESOURCE_URL_NODE}masterdata/opportunities`)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
};

const getContracts = (query: any): Promise<any | undefined> => {
    return axios
        .get(`${RESOURCE_URL_NODE}masterdata/contracts`)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data);
};

const createProjectApi = async (task: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}projects/create
            `, task).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};


const updateProjectApi = async (task: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}projects/update-data
            `, task).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const getTaskDetailCapsule = async (query: any): Promise<any | undefined> => {
    try {
        let { data } = await axios
            .post(`${RESOURCE_URL_NODE}projects/gettaskcapsule`, query)
        return data?.data;
    } catch (e: any) {
        console.log(e.message)
    }
};

const updateProjectStatus = async (payload: any): Promise<any | undefined> => {
    try {
        const response = await axios.post(`${RESOURCE_URL_NODE}projects/update`, payload);
        return response;
    } catch (error) {
        console.error("Error updating project status:", error);
        return error.response;
    }
};

const updateTaskStatus = async (payload: any): Promise<any | undefined> => {
    try {
        const response = await axios.post(`${RESOURCE_URL_NODE}projects/update-task`, payload);
        return response;
    } catch (error) {
        console.error("Error updating task status:", error);
        return error.response;
    }
};

const getProjectDetails = async (id: any): Promise<any | undefined> => {
    try {
        const response = await axios.get(`${RESOURCE_URL_NODE}projects/${id}`);
        return response;
    } catch (error) {
        console.error("Error updating task status:", error);
        return error.response;
    }
};

const CopyProjectDetails = async (id: any): Promise<any | undefined> => {
    try {
        const response = await axios.post(`${RESOURCE_URL_NODE}projects/copy/project`,{ id });
        return response.data;
      } catch (error) {
        console.error("Error updating task status:", error);
        return error.response;
      }
}
const getProjectBreakdown = async (id: any): Promise<any | undefined> => {
    try {
        const response = await axios.post(`${RESOURCE_URL_NODE}projects/projectBreakdown`, { projectId: id });
        return response;
    } catch (error) {
        console.error("Error while fatching project breakdown:", error);
        return error.response;
    }
};

const projectCopyTaskTicket = async (id: any): Promise<any | undefined> => {
    try {
        const response = await axios.post(`${RESOURCE_URL_NODE}projects/copy/task`, { id });
        return response;
    } catch (error) {
        console.error("Error while fatching project breakdown:", error);
        return error.response;
    }
};

const projectCopyPhase = async (id: any): Promise<any | undefined> => {
    try {
        const response = await axios.post(`${RESOURCE_URL_NODE}projects/copy/phase`, { id });
        return response;
    } catch (error) {
        console.error("Error while copy project:", error);
        return error.response;
    }
};
const projectDeletePhase = async (payload: any): Promise<any | undefined> => {
    try {
        const response = await axios.post(`${RESOURCE_URL_NODE}projects/delete/phase`, payload);
        return response;
    } catch (error) {
        console.error("Error while copy project:", error);
        return error.response;
    }
};

const updateMultipleTaskOffset = async (payload: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}projects/bulk-update-tasks
            `, payload).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

const projectDeleteTask = async (payload: any): Promise<any | undefined> => {
    try {
        const response = await axios.post(`${RESOURCE_URL_NODE}projects/delete/task`, payload);
        return response;
    } catch (error) {
        console.error("Error while copy project:", error);
        return error.response;
    }
};

const projectUpdateTask = async (payload: any): Promise<any | undefined> => {
    try {
        const response = await axios.post(`${RESOURCE_URL_NODE}projects/edit/task`, payload);
        return response;
    } catch (error) {
        console.error("Error while copy project:", error);
        return error.response;
    }
};
const getPhaseDetails = async (id: any, projectID : any): Promise<any | undefined> => {
    try {
        const response = await axios.post(`${RESOURCE_URL_NODE}projects/breakdown/phase`, { phaseID: id, projectID: projectID });
        return response;
    } catch (error) {
        console.error("Error while fatching project breakdown:", error);
        return error.response;
    }
};

export {
    getResources,
    getResourceAvailability,
    getToPlan,
    assignServiceCallToResource,
    assignTaskToResource,
    assignTicketToResource,
    getResourceRole,
    getResourcesWorkType,
    getResourceCapacity,
    getToPlanStatus,
    spilteTasks,
    getToPlanServiceCallStatus,
    getToPlanTaskStatus,
    updatePopupvalues,
    lastSyncData,
    getMonthdata,
    deleteServicecall,
    createtaskApi,
    getProjectTask,
    createticketApi,
    getPhase,
    saveLayoutandfilters,
    getLayoutandfilters,
    getLayoutandFiltersList,
    getProjectStatus,
    updateTasks,
    getTaskDetail,
    getSidebarList,
    getServicecallDetail,
    deleteLayoutAndFilterList,
    selectLayoutAndMakeActive,
    editLayoutandFilterName,
    createPhaseApi,
    getProjectType,
    getOpportunities,
    getContracts,
    createProjectApi,
    getTaskTicketNotes,
    createPopupvalues,
    getTaskDetailCapsule,
    getToPlanTaskStatusAdmin,
    getToPlanServiceCallStatusAdmin,
    updateProjectStatus,
    updateTaskStatus,
    getProjectDetails,
    updateProjectApi,
    getTaskTicketList,
    getPlanningData,
    getProjectBreakdown,
    projectCopyTaskTicket,
    projectCopyPhase,
    CopyProjectDetails,
    updateMultipleTaskOffset,
    projectDeletePhase,
    projectDeleteTask,
    projectUpdateTask,
    getTaskDetailforBradkdown,
    getPhaseDetails,
    updateDraftPhase
};
