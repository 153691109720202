import React, { FC, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import { DropDown } from "./DropDown";
type Props = {
  filterArray?: any;
  view?: any;
  openDropdown?:any;
  setOpenDropdown?:any;
  toggleDropdown?:any;
  setPayload?:any;
  payload?:any;
  general_settings?:any
  taskListType?:any
  isCompanyVisable?:any
};
const CommonFilter: FC<Props> = ({ filterArray, view, openDropdown,toggleDropdown, setPayload, payload,general_settings, taskListType ,isCompanyVisable}) => {
  const [currentClick, setcurrentClick] = useState("");
  const [showproject, setshowproject] = useState("d-none");
  const [showrole, setshowrole] = useState("d-none");
  const [showpeople, setshowpeople] = useState("d-none");
  const [showdepartment, setshowdepartment] = useState("d-none");
  const [showskill, setshowskill] = useState("d-none");
  const [showqueues, setshowqueues] = useState("d-none");
  const [showlineOfBusiness, setshowlineOfBusiness] = useState("d-none");
  const [Projectid, setProjectid] = useState<any>([]);
  const [Roleid, setRoleid] = useState<any>([]);
  const [ProjectName, setProjectName] = useState<any>([]);
  const [RoleName, setRoleName] = useState<any>([]);
  const [Peopletid, setPeopletid] = useState<any>([]);
  const [PeopletName, setPeopletName] = useState<any>([]);
  const [Departmentid, setDepartmentid] = useState<any>([]);
  const [DepartmentName, setDepartmentName] = useState<any>([]);
  const [projectinputText, setprojectinputText] = useState("");
  const [roleinputText, setroleinputText] = useState("");
  const [peopleinputText, setpeopleinputText] = useState("");
  const [departmentinputText, setdepartmentinputText] = useState("");
  const [queuesinputText, setqueuesinputText] = useState("");
  const [skillinputText, setskillinputText] = useState("");
  const [lineOfBusinessinputText, setlineOfBusinessinputText] = useState("");
  const [Skillid, setSkillid] = useState<any>([]);
  const [queuesid, setqueuesid] = useState<any>([]);
  const [lineOfBusinessid, setlineOfBusinessid] = useState<any>([]);
  const [SkillName, setSkillName] = useState<any>([]);
  const [queuesName, setqueuesName] = useState<any>([]);
  const [lineOfBusinessName, setlineOfBusinessName] = useState<any>([]);
  //company
  const [CompanyName, setCompanyName] = useState<any>([]);
  const [Companyid, setCompanyid] = useState<any>(Array);
  const [showcompany, setshowcompany] = useState('d-none');
  const [companyinputText, setcompanyinputText] = useState("");

  //project lead
  const [showprojectlead, setshowprojectlead] = useState('d-none');
  const [projectleadinputText, setprojectleadinputText] = useState("");
  const [projectleadid, setprojectleadid] = useState<any>([]);
  const [projectleadName, setprojectleadName] = useState<any>([]);
  //priority
  const [showpriorities, setshowpriorities] = useState('d-none');
  const [prioritiesinputText, setprioritiesinputText] = useState("");
  const [prioritiesid, setprioritiesid] = useState<any>([]);
  const [prioritiesName, setprioritiesName] = useState<any>([]);
  //slas
  const [showslas, setshowslas] = useState('d-none');
  const [slasinputText, setslasinputText] = useState("");
  const [slasid, setslasid] = useState<any>([]);
  const [slasName, setslasName] = useState<any>([]);
  //ticketcategory
  const [showticketcategory, setshowticketcategory] = useState('d-none');
  const [ticketcategoryinputText, setticketcategoryinputText] = useState("");
  const [ticketcategoryid, setticketcategoryid] = useState<any>([]);
  const [ticketcategoryName, setticketcategoryName] = useState<any>([]);
  //tickettype
  const [showtickettype, setshowtickettype] = useState('d-none');
  const [tickettypeinputText, settickettypeinputText] = useState("");
  const [tickettypeid, settickettypeid] = useState<any>([]);
  const [tickettypeName, settickettypeName] = useState<any>([]);
  //taskcategory
  const [showtaskcategory, setshowtaskcategory] = useState('d-none');
  const [taskcategoryinputText, settaskcategoryinputText] = useState("");
  const [taskcategoryid, settaskcategoryid] = useState<any>([]);
  const [taskcategoryName, settaskcategoryName] = useState<any>([]);
  //contractcategory
  const [showcontractcategory, setshowcontractcategory] = useState('d-none');
  const [contractcategoryinputText, setcontractcategoryinputText] = useState("");
  const [contractcategoryid, setcontractcategoryid] = useState<any>([]);
  const [contractcategoryName, setcontractcategoryName] = useState<any>([]);

  //task/ticket status
  const [showtaskticketstatus, setshowtaskticketstatus] = useState('d-none');
  const [taskticketstatusinputText, settaskticketstatusinputText] = useState("");
  const [taskticketstatusid, settaskticketstatusid] = useState<any>([]);
  const [taskticketstatusName, settaskticketstatusName] = useState<any>([]);

  const clearAllFilter = () => {
    let ar: any = [];
    setProjectid([]);
    setProjectName([]);
    setshowproject("d-none");
    setprojectinputText("");

    setRoleid([]);
    setRoleName([]);
    setshowrole("d-none");
    setroleinputText("");

    setshowpeople("d-none");
    setPeopletid(ar);
    setPeopletName(ar);
    setpeopleinputText("");

    setDepartmentid(ar);
    setDepartmentName(ar);
    setshowdepartment("d-none");
    setdepartmentinputText("");

    setSkillid(ar);
    setSkillName(ar);
    setshowskill("d-none");
    setskillinputText("");

    setshowqueues("d-none");
    setqueuesid(ar);
    setqueuesName(ar);
    setqueuesinputText("");

    setlineOfBusinessid(ar);
    setlineOfBusinessName(ar);
    setshowlineOfBusiness("d-none");
    setlineOfBusinessinputText("");

    setprojectleadid(ar);
    setprojectleadName(ar)
    setshowprojectlead('d-none');
    setprojectleadinputText("");

    setprioritiesid(ar);
    setprioritiesName(ar)
    setshowpriorities('d-none');
    setprioritiesinputText("");

    setticketcategoryid(ar);
    setticketcategoryName(ar)
    setshowticketcategory('d-none');
    setticketcategoryinputText("");

    settickettypeid(ar);
    settickettypeName(ar)
    setshowtickettype('d-none');
    settickettypeinputText("");

    settaskcategoryid(ar);
    settaskcategoryName(ar)
    setshowtaskcategory('d-none');
    settaskcategoryinputText("");

    setslasid(ar);
    setslasName(ar)
    setshowslas('d-none');
    setslasinputText("");

    setcontractcategoryid(ar);
    setcontractcategoryName(ar)
    setshowcontractcategory('d-none');
    setcontractcategoryinputText("");

    settaskticketstatusid(ar);
    settaskticketstatusName(ar)
    setshowtaskticketstatus('d-none');
    settaskticketstatusinputText("");

    setCompanyid(ar);
    setCompanyName(ar)
    setshowcompany('d-none');
    setcompanyinputText("");
  };

  const hideSelection = (e: any) => {
    const ar: any[] = [];
    const latestpayload = JSON.parse(JSON.stringify(payload));
    switch (e.target.value) {
      case "Project":
        setProjectid([]);
        setProjectName([]);
        setshowproject("d-none");
        setprojectinputText("");
        latestpayload.projects = [];
        break;
  
      case "Role":
        setRoleid([]);
        setRoleName([]);
        setshowrole("d-none");
        setroleinputText("");
        latestpayload.roles = [];
        break;
  
      case "People":
        setshowpeople("d-none");
        setPeopletid(ar);
        setPeopletName(ar);
        setpeopleinputText("");
        latestpayload.resources = [];
        break;
  
      case "Department":
        setDepartmentid(ar);
        setDepartmentName(ar);
        setshowdepartment("d-none");
        setdepartmentinputText("");
        latestpayload.departments = [];
        break;
  
      case "Queues":
        setshowqueues("d-none");
        setqueuesid(ar);
        setqueuesName(ar);
        setqueuesinputText("");
        latestpayload.queues = [];
        break;
  
      case "Skills":
        setshowskill("d-none");
        setSkillid(ar);
        setSkillName(ar);
        setskillinputText("");
        latestpayload.skills = [];
        break;
  
      case "LineOfBusiness":
        setshowlineOfBusiness("d-none");
        setlineOfBusinessid(ar);
        setlineOfBusinessName(ar);
        setlineOfBusinessinputText("");
        latestpayload.lineOfBusiness = [];
        break;
  
      case 'projectlead':
        setprojectleadid(ar);
        setprojectleadName(ar);
        setshowprojectlead('d-none');
        setprojectleadinputText("");
        latestpayload.project_leads = [];
        break;
  
      case 'Priorities':
        setprioritiesid(ar);
        setprioritiesName(ar);
        setshowpriorities('d-none');
        setprioritiesinputText("");
        latestpayload.priority = [];
        break;
  
      case 'SLAs':
        setslasid(ar);
        setslasName(ar);
        setshowslas('d-none');
        setslasinputText("");
        latestpayload.sla = [];
        break;
  
      case 'Ticket category':
        setticketcategoryid(ar);
        setticketcategoryName(ar);
        setshowticketcategory('d-none');
        setticketcategoryinputText("");
        latestpayload.ticket_categories = [];
        break;
  
      case 'Ticket type':
        settickettypeid(ar);
        settickettypeName(ar);
        setshowtickettype('d-none');
        settickettypeinputText("");
        latestpayload.ticket_types = [];
        break;
  
      case 'Task category':
        settaskcategoryid(ar);
        settaskcategoryName(ar);
        setshowtaskcategory('d-none');
        settaskcategoryinputText("");
        latestpayload.task_categories = [];
        break;
  
      case 'Contract category':
        setcontractcategoryid(ar);
        setcontractcategoryName(ar);
        setshowcontractcategory('d-none');
        setcontractcategoryinputText("");
        latestpayload.contract_category = [];
        break;
  
      case 'Statuses':
        settaskticketstatusid(ar);
        settaskticketstatusName(ar);
        setshowtaskticketstatus('d-none');
        settaskticketstatusinputText("");
        latestpayload.statuses = [];
        break;
  
      case 'Company':
        setCompanyid(ar);
        setCompanyName(ar);
        setshowcompany('d-none');
        setcompanyinputText("");
        latestpayload.companies = [];
        break;
  
      default:
        clearAllFilter();
        latestpayload.projects = [];
        latestpayload.roles = [];
        latestpayload.resources = [];
        latestpayload.departments = [];
        latestpayload.queues = [];
        latestpayload.skills = [];
        latestpayload.lineOfBusiness = [];
        latestpayload.statuses = [];
        latestpayload.companies = [];
        latestpayload.ticket_categories=[]
        latestpayload.contract_category = [];
        latestpayload.ticket_types = []
        latestpayload.sla = []
        break;
    }
  
    setPayload(latestpayload);
  };

  const ChangeFilter = (e: any) => {
    const dataName = e.target.getAttribute("data-name");
    switch (dataName) {
      case "project":
        setshowproject("");
        setcurrentClick("Project");
        toggleDropdown("common-Project");
        break;
  
      case "role":
        setshowrole("");
        setcurrentClick("Role");
        toggleDropdown("common-Role");
        break;
  
      case "people":
        setshowpeople("");
        setcurrentClick("People");
        toggleDropdown("common-People");
        break;
  
      case "department":
        setshowdepartment("");
        setcurrentClick("Department");
        toggleDropdown("common-Department");
        break;
  
      case "Skills":
        setshowskill("");
        setcurrentClick("Skills");
        toggleDropdown("common-Skills");
        break;
  
      case "queues":
        setshowqueues("");
        setcurrentClick("Queues");
        toggleDropdown("common-Queues");
        break;
  
      case "LineOfBusiness":
        setshowlineOfBusiness("");
        setcurrentClick("LineOfBusiness");
        toggleDropdown("common-LineOfBusiness");
        break;
  
      case 'projectlead':
        setshowprojectlead('');
        setcurrentClick('projectlead');
        toggleDropdown("common-projectlead");
        break;
  
      case 'priorities':
        setshowpriorities('');
        setcurrentClick('Priorities');
        toggleDropdown("common-Priorities");
        break;
  
      case 'tickettype':
        setshowtickettype('');
        setcurrentClick('Ticket type');
        toggleDropdown("common-Ticket type");
        break;
  
      case 'ticketcategory':
        setshowticketcategory('');
        setcurrentClick('Ticket category');
        toggleDropdown("common-Ticket category");
        break;
  
      case 'taskcategory':
        setshowtaskcategory('');
        setcurrentClick('Task category');
        toggleDropdown("common-Task category");
        break;
  
      case 'slas':
        setshowslas('');
        setcurrentClick('SLAs');
        toggleDropdown("common-SLAs");
        break;
  
      case 'contractcategory':
        setshowcontractcategory('');
        setcurrentClick('Contract category');
        toggleDropdown("common-Contract category");
        break;
  
      case 'statuses':
        setshowtaskticketstatus('');
        setcurrentClick('Statuses');
        toggleDropdown("common-Statuses");
        break;
  
      case 'company':
        setshowcompany('');
        setcurrentClick('Company');
        toggleDropdown("common-Company");
        break;
  
      default:
        break;
    }
  };
  

  const storeFilterValue = (value: any) => {
    let latestpayload = JSON.parse(JSON.stringify(payload));
  
    switch (value) {
      case "Project":
      case "all":
        latestpayload.projects = Projectid;
        break;
  
      case "Role":
      case "all":
        latestpayload.roles = Roleid;
        break;
  
      case "People":
      case "all":
        latestpayload.resources = Peopletid;
        break;
  
      case "Department":
      case "all":
        latestpayload.departments = Departmentid;
        break;
  
      case "Skills":
      case "all":
        latestpayload.skills = Skillid;
        break;
  
      case "Queues":
      case "all":
        latestpayload.queues = queuesid;
        break;
  
      case "LineOfBusiness":
      case "all":
        latestpayload.lineOfBusiness = lineOfBusinessid;
        break;
  
      case 'projectlead':
      case "all":
        latestpayload.project_leads = projectleadid;
        break;
  
      case 'Priorities':
      case "all":
        latestpayload.priority = prioritiesid;
        break;
  
      case 'Ticket type':
      case "all":
        latestpayload.ticket_types = tickettypeid;
        break;
  
      case 'Ticket category':
      case "all":
        latestpayload.ticket_categories = ticketcategoryid;
        break;
  
      case 'Task category':
      case "all":
        latestpayload.task_categories = taskcategoryid;
        break;
  
      case 'SLAs':
      case "all":
        latestpayload.sla = slasid;
        break;
  
      case 'Contract category':
      case "all":
        latestpayload.contract_category = contractcategoryid;
        break;
  
       case 'Statuses':
       case "all":
        latestpayload.statuses = taskticketstatusid;
        break;

        case 'Company':
        case "all":
        latestpayload.companies = Companyid;
        break;
  
       default:
        break;
    }
  
    setPayload(latestpayload);
  };
  

  const resetSelectedItems = (value: any) => {
    const resetArray = [
      "Company", "Project", "Role", "People", "Department", "Skills", "Queues", 
      "LineOfBusiness", 'projectlead', 'Priorities', 'Ticket type', 
      'Ticket category', 'Task category', 'SLAs', 'Contract category', 
      'Statuses'
    ];

    const resetMappings = {
      "Company": [setCompanyid, payload.companies],
      "Project": [setProjectid, payload.projects],
      "Role": [setRoleid, payload.roles],
      "People": [setPeopletid, payload.resources],
      "Department": [setDepartmentid, payload.departments],
      "Skills": [setSkillid, payload.skills],
      "Queues": [setqueuesid, payload.queues],
      "LineOfBusiness": [setlineOfBusinessid, payload.lineOfBusiness],
      "projectlead": [setprojectleadid, payload.project_leads],
      "Priorities": [setprioritiesid, payload.priority],
      "Ticket type": [settickettypeid, payload.ticket_types],
      "Ticket category": [setticketcategoryid, payload.ticket_categories],
      "Task category": [settaskcategoryid, payload.task_categories],
      "SLAs": [setslasid, payload.sla],
      "Contract category": [setcontractcategoryid, payload.contract_category],
      "Statuses": [settaskticketstatusid, payload.statuses]
    };
  
    resetArray?.forEach(key => {
      if (resetMappings[key]) {
        const [setterFunction, data] = resetMappings[key];
        setterFunction(data);
      }
    });
  };
  
  

const handleChange = (e: any) => {
    const dataId = e.target.getAttribute("data-id");
    const value = parseInt(e.target.value);
    const isChecked = e.target.checked;

    const stateUpdaters: { [key: string]: Function } = {
        "Project": setProjectid,
        "Role": setRoleid,
        "People": setPeopletid,
        "Department": setDepartmentid,
        "Skills": setSkillid,
        "Queues": setqueuesid,
        "LineOfBusiness": setlineOfBusinessid,
        "projectlead": setprojectleadid,
        "Priorities": setprioritiesid,
        "Ticket type": settickettypeid,
        "Ticket category": setticketcategoryid,
        "Task category": settaskcategoryid,
        "SLAs": setslasid,
        "Contract category": setcontractcategoryid,
        "Statuses": settaskticketstatusid,
        "Company": setCompanyid,
    };

    if (stateUpdaters[dataId]) {
        stateUpdaters[dataId]((currentValues: any[]) =>
            isChecked
                ? [...currentValues, value]
                : currentValues.filter((item: any) => item !== value)
        );
    }
  };


  let inputHandler = (e: any) => {
    // Convert input text to lower case
    const lowerCase = e.target.value.toLowerCase();

    const inputSetters: { [key: string]: Function } = {
        "People-search": setpeopleinputText,
        "Department-search": setdepartmentinputText,
        "Project-search": setprojectinputText,
        "Role-search": setroleinputText,
        "Skills-search": setskillinputText,
        "Queues-search": setqueuesinputText,
        "LineOfBusiness-search": setlineOfBusinessinputText,
        "projectlead-search": setprojectleadinputText,
        "Priorities-search": setprioritiesinputText,
        "Ticket type-search": settickettypeinputText,
        "Ticket category-search": setticketcategoryinputText,
        "Task category-search": settaskcategoryinputText,
        "SLAs-search": setslasinputText,
        "Contract category-search": setcontractcategoryinputText,
        "Statuses-search": settaskticketstatusinputText,
        "Company-search": setcompanyinputText,
    };

    const setter = inputSetters[e.target.id];
    if (setter) {
        setter(lowerCase);
    }
};


const selectAll = (e: any) => {
  const { id, checked } = e.target;

  const filterAndMapIds = (condition: (item: any) => boolean, items: any[] = []) =>
    checked ? items.filter(condition).map(x => parseInt(x.id)) : [];

  const selectionActions = {
    "Project": filterAndMapIds(twork => twork.isActive && !twork.isCompleted, filterArray?.projects),
    "Role": filterAndMapIds(twork => twork.isActive, filterArray?.roles),
    "People": filterAndMapIds(twork => twork.isActive, filterArray?.peoples),
    "Department": filterAndMapIds(() => true, filterArray?.departments?.items),
    "Skills": filterAndMapIds(twork => twork.isActive, filterArray?.skills?.data),
    "Queues": filterAndMapIds(twork => twork.isActive, filterArray?.queues?.data),
    "LineOfBusiness": filterAndMapIds(twork => twork.isActive, filterArray?.lineOfBusiness?.data),
    "projectlead": filterAndMapIds(twork => twork.isActive, filterArray?.peoples),
    "Priorities": filterAndMapIds(twork => twork.isActive, filterArray?.priorities?.data),
    "Ticket type": filterAndMapIds(twork => twork.isActive, filterArray?.ticketType?.data),
    "Ticket category": filterAndMapIds(twork => twork.isActive, filterArray?.ticketCategory?.data),
    "Task category": filterAndMapIds(twork => twork.isActive, filterArray?.taskCategory?.data),
    "SLAs": filterAndMapIds(twork => twork.isActive, filterArray?.slas?.data),
    "Contract category": filterAndMapIds(twork => twork.isActive, filterArray?.contractCategory?.data),
    "Statuses": filterAndMapIds(twork => twork.isActive, filterArray?.taskticketstatus),
    "Company": filterAndMapIds(twork => twork.isActive, filterArray?.companies),
  };

  const setters = {
      "Project": setProjectid,
      "Role": setRoleid,
      "People": setPeopletid,
      "Department": setDepartmentid,
      "Skills": setSkillid,
      "Queues": setqueuesid,
      "LineOfBusiness": setlineOfBusinessid,
      "projectlead": setprojectleadid,
      "Priorities": setprioritiesid,
      "Ticket type": settickettypeid,
      "Ticket category": setticketcategoryid,
      "Task category": settaskcategoryid,
      "SLAs": setslasid,
      "Contract category": setcontractcategoryid,
      "Statuses": settaskticketstatusid,
      "Company": setCompanyid,
  };

  if (selectionActions[id]) {
      setters[id](selectionActions[id]);
  }
};



  useEffect(() => {
    const isAllFetchComplete = ![
      filterArray?.isCompanyFetch,
      filterArray?.isfetchlayoutandfilters,
      filterArray?.isPeopleFetch,
      filterArray?.isDepartmentFetch,
      filterArray?.isProjectFetch,
      filterArray?.isSkillFetch,
      filterArray?.isRoleFetch,
      filterArray?.isQueuesFetch,
      filterArray?.isTicketCategoryFetch,
      filterArray?.isTicketTypeFetch,
      filterArray?.isTaskCategoryFetch,
      filterArray?.isSlasFetch,
      filterArray?.isContractCategoryFetch,
      filterArray?.isfetchstatus,
    ].some(Boolean);

    const updateState = (
      data: any,
      dataKey: string,
      filterArray: any,
      stateSetter: Function,
      showSetter: Function,
      filterCondition = (twork: any) => twork.isActive === true
    ) => {
      if (data?.[dataKey]?.length > 0) {
        const ids = filterArray
          ?.filter(filterCondition)
          ?.map((item: any) => parseInt(item.id));
        const filteredData = data?.[dataKey]?.filter((id: any) =>
          ids?.includes(id)
        );
        console.log(filteredData,dataKey)
        stateSetter(filteredData);
        showSetter("");
      }
    };

    if (isAllFetchComplete) {
      const data = JSON.parse(JSON.stringify(payload));

      updateState(data, "companies", filterArray?.companies, setCompanyid, setshowcompany);
      updateState(data, "resources", filterArray?.peoples, setPeopletid, setshowpeople);
      updateState(data, "departments", filterArray?.departments?.items, setDepartmentid, setshowdepartment, () => true);
      updateState(data, "projects", filterArray?.projects, setProjectid, setshowproject, (twork: any) => twork.isActive === true && twork.isCompleted === false);
      updateState(data, "roles", filterArray?.roles, setRoleid, setshowrole);
      updateState(data, "queues", filterArray?.queues?.data, setqueuesid, setshowqueues);
      updateState(data, "skills", filterArray?.skills?.data, setSkillid, setshowskill);
      updateState(data, "lineOfBusiness", filterArray?.lineOfBusiness?.data, setlineOfBusinessid, setshowlineOfBusiness, () => true);
      updateState(data, "contract_category", filterArray?.contractCategory?.data, setcontractcategoryid, setshowcontractcategory);
      updateState(data, "priority", filterArray?.priorities?.data, setprioritiesid, setshowpriorities);
      updateState(data, "project_leads", filterArray?.peoples, setprojectleadid, setshowprojectlead);
      updateState(data, "sla", filterArray?.slas?.data, setslasid, setshowslas);
      updateState(data, "task_categories", filterArray?.taskCategory?.data, settaskcategoryid, setshowtaskcategory);
      updateState(data, "ticket_categories", filterArray?.ticketCategory?.data, setticketcategoryid, setshowticketcategory);
      updateState(data, "ticket_types", filterArray?.ticketType?.data, settickettypeid, setshowtickettype);
      updateState(data, "statuses", filterArray?.taskticketstatus, settaskticketstatusid, setshowtaskticketstatus);
    }
  }, [
    payload,
    filterArray?.isCompanyFetch,
    filterArray?.isfetchlayoutandfilters,
    filterArray?.isPeopleFetch,
    filterArray?.isDepartmentFetch,
    filterArray?.isProjectFetch,
    filterArray?.isSkillFetch,
    filterArray?.isRoleFetch,
    filterArray?.isQueuesFetch,
    filterArray?.isTicketCategoryFetch,
    filterArray?.isTicketTypeFetch,
    filterArray?.isTaskCategoryFetch,
    filterArray?.isSlasFetch,
    filterArray?.isContractCategoryFetch,
    filterArray?.isfetchstatus,
  ]);


  
  useEffect(() => {
    resetSelectedItems()
  },[openDropdown])


  useEffect(() => {
    if (![
      filterArray?.isPeopleFetch,
      filterArray?.isDepartmentFetch,
      filterArray?.isProjectFetch,
      filterArray?.isSkillFetch,
      filterArray?.isRoleFetch,
      filterArray?.isQueuesFetch,
      filterArray?.isTicketCategoryFetch,
      filterArray?.isSlasFetch,
      filterArray?.isfetchstatus
    ].some(Boolean)) {
  
      const updateNameArray = (
        filterData: any,
        idArray: any,
        setNameFunction: Function
      ) => {
        const nameArray = filterData
          ?.filter((item: { id: any }) => idArray.includes(item.id))
          ?.map((item: { name: any }) => item.name);
        setNameFunction(nameArray);
      };
  
      updateNameArray(filterArray?.companies, Companyid, setCompanyName);
      updateNameArray(filterArray?.peoples, Peopletid, setPeopletName);
      updateNameArray(filterArray?.departments?.items, Departmentid, setDepartmentName);
      updateNameArray(filterArray?.projects, Projectid, setProjectName);
      updateNameArray(filterArray?.roles, Roleid, setRoleName);
      updateNameArray(filterArray?.queues?.data, queuesid, setqueuesName);
      updateNameArray(filterArray?.skills?.data, Skillid, setSkillName);
      updateNameArray(filterArray?.lineOfBusiness?.data, lineOfBusinessid, setlineOfBusinessName);
      updateNameArray(filterArray?.contractCategory?.data, contractcategoryid, setcontractcategoryName);
      updateNameArray(filterArray?.priorities?.data, prioritiesid, setprioritiesName);
      updateNameArray(filterArray?.peoples, projectleadid, setprojectleadName);
      updateNameArray(filterArray?.slas?.data, slasid, setslasName);
      updateNameArray(filterArray?.taskCategory?.data, taskcategoryid, settaskcategoryName);
      updateNameArray(filterArray?.ticketCategory?.data, ticketcategoryid, setticketcategoryName);
      updateNameArray(filterArray?.ticketType?.data, tickettypeid, settickettypeName);
      updateNameArray(filterArray?.taskticketstatus, taskticketstatusid, settickettypeName);
    }
  }, [
    Companyid, Peopletid, Departmentid, Projectid, Roleid, queuesid, Skillid, lineOfBusinessid, contractcategoryid, prioritiesid, projectleadid, slasid, taskcategoryid, ticketcategoryid, tickettypeid, taskticketstatusid
  ]);
  
  
  return (
    <>
      <div className="dropDown d-flex align-items-center">
        <button
          onClick={()=> toggleDropdown("common-filter")}
          className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-0 h-32px fs-16 d-flex align-items-center"
          type="button"
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-start"
          data-kt-menu-flip="bottom"
        >
          <KTSVG
            path="/media/icons/duotune/new-icons/filter.svg"
            className="me-2"
            stylecode={{ color: "inherit" }}
            svgClassName="w-16px h-14px"
          />
          Filter
        </button>

        {(showproject === "" ||
          showrole === "" ||
          showpeople === "" ||
          showdepartment === "" ||
          showskill === "" ||
          showqueues === "" ||
          showprojectlead === "" ||
          showcontractcategory === "" ||
          showtaskcategory === "" ||
          showticketcategory === "" ||
          showpriorities === "" ||
          showtickettype === "" ||
          showlineOfBusiness === "" || showtaskticketstatus === "" ||
          (showcompany === "" && isCompanyVisable)) && (
          <OverlayTrigger
            key="copy-to-clipboard"
            trigger={["hover", "focus"]}
            placement="bottom"
            overlay={
              <Tooltip
                id="tooltip-copy-to-clipboard"
                className="position-absulute"
              >
                <span className="pe-2">Clear all</span>
              </Tooltip>
            }
          >
            <span>
              <button
                value="All"
                onClick={hideSelection}
                className="p-0 bg-transparent border-0 w-auto h-auto ms-3 me-5"
              >
                <KTSVG
                  path="/media/icons/duotune/extra/close.svg"
                  className="text-primary pe-none"
                  svgClassName="w-10px h-10px"
                />
              </button>
            </span>
          </OverlayTrigger>
        )}
        {openDropdown === "common-filter" &&
          <div
            className="menu menu-sub menu-sub-dropdown bg-white px-3 py-5 min-w-130px fs-14 show position-absolute top-100 start-0 mh-240px overflow-auto"
            data-kt-menu="true"
          >
            <a
              onClick={ChangeFilter}
              data-name="people"
              className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap"
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/resources.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Resources
            </a>
            <a onClick={ChangeFilter} data-name="company"
                className={`d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap ${isCompanyVisable ? "" : "d-none"}`}
                href="#"
                >
                <KTSVG path="/media/icons/duotune/new-icons/company.svg" 
                className='w-20px me-1 '
                stylecode={{ color: "#164387" }}
                svgClassName="w-16px h-auto"
              />
              Company
              </a>
            <a
              onClick={ChangeFilter}
              data-name="department"
              className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap"
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/extra/mouse-square.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Department
            </a>
            <a
              className={`d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap`}
              data-name="project"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/project.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Project
            </a>
            <a
              className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap"
              data-name="role"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/role.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Role
            </a>
            <a
              className={`d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap`}
              data-name="projectlead"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/project-leads.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Project Leads
            </a>
            {taskListType === "Ticket" &&
            <a
              className={`d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap`}
              data-name="queues"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/queues.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Queues
            </a>
            }

            {general_settings?.show_resource_skills === true && (
              <a
                className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap"
                data-name="Skills"
                onClick={ChangeFilter}
                href="#"
              >
                <KTSVG
                  path="/media/icons/duotune/new-icons/skills.svg"
                  stylecode={{ color: "#164387" }}
                  className="w-20px me-1"
                  svgClassName="w-16px h-auto"
                />
                Skills
              </a>
            )}
            <a
              className={`d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap`}
              data-name="LineOfBusiness"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/extra/note.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Line of business
            </a>
            {taskListType === "Ticket" &&
            <><a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="priorities" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/priorities.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Priorities</a>
            <a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="tickettype" onClick={ChangeFilter} href="#">
              <KTSVG path="/media/icons/duotune/new-icons/ticket-type.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Ticket type</a>
            <a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="ticketcategory" onClick={ChangeFilter} href="#">
              <KTSVG path="/media/icons/duotune/new-icons/ticket-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Ticket category</a>
            <a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="slas" onClick={ChangeFilter} href="#">
              <KTSVG path="/media/icons/duotune/new-icons/slas.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />SLAs</a>
            <a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="contractcategory" onClick={ChangeFilter} href="#">
              <KTSVG path="/media/icons/duotune/new-icons/contract-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Contract category</a></>
            }
            {taskListType === "Task" &&
            <a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="taskcategory" onClick={ChangeFilter} href="#">
              <KTSVG path="/media/icons/duotune/new-icons/task-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Task category</a>
            }
            <a className="d-flex align-items-center text-black fw-semibold text-hover-primary text-nowrap" data-name="statuses" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/task-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Task/Ticket status</a>
          </div>
        }
      </div>
      {showpeople === "" &&
        <DropDown
          imgpath="/media/icons/duotune/extra/profile-user-2.svg"
          inputHandler={inputHandler}
          inputText={peopleinputText}
          currentClick={currentClick}
          checked_ids={Peopletid}
          title="Resources"
          id="People"
          data={filterArray?.peoples?.filter(
            (twork: any) => twork.isActive === true
          )}
          isLoading={filterArray?.isPeopleFetch}
          showdropdown={showpeople}
          hideSelection={hideSelection}
          storeFilterValue={storeFilterValue}
          handleChange={handleChange}
          selectedName={PeopletName}
          selectAll={selectAll}
          tooltipTitle="Filter resources on name."
          isOpen={openDropdown === 'common-People'}
          toggleDropdown={toggleDropdown}
          filterType={"common"}
        ></DropDown>
      }
      {showdepartment === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/mouse-square.svg"
        inputHandler={inputHandler}
        inputText={departmentinputText}
        currentClick={currentClick}
        checked_ids={Departmentid}
        title="Department"
        id="Department"
        data={filterArray?.departments?.items}
        isLoading={filterArray?.isDepartmentFetch}
        showdropdown={showdepartment}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={DepartmentName}
        selectAll={selectAll}
        tooltipTitle="Filter resources on their default department."
        isOpen={openDropdown === 'common-Department'}
        toggleDropdown={toggleDropdown}
        filterType={"common"}
      ></DropDown>
      }
      {showproject === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={projectinputText}
        currentClick={currentClick}
        checked_ids={Projectid}
        title="Project"
        id="Project"
        data={filterArray?.projects?.filter(
          (twork: any) => twork.isActive === true && twork.isCompleted === false && payload.companies.includes(twork.companyID)
        )}
        isLoading={filterArray?.isProjectFetching}
        showdropdown={showproject}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={ProjectName}
        selectAll={selectAll}
        view={view}
        tooltipTitle="Filter resources on their project."
        isOpen={openDropdown === 'common-Project'}
        toggleDropdown={toggleDropdown}
        filterType={"common"}
        company={payload?.companies}
      ></DropDown>
      }
      {showrole === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={roleinputText}
        currentClick={currentClick}
        checked_ids={Roleid}
        title="Role"
        id="Role"
        data={filterArray?.roles?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.isRoleFetch}
        showdropdown={showrole}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={RoleName}
        selectAll={selectAll}
        tooltipTitle="Filter resources on their roles."
        isOpen={openDropdown === 'common-Role'}
        toggleDropdown={toggleDropdown}
        filterType={"common"}
      ></DropDown>
      }
      {showqueues === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={queuesinputText}
        currentClick={currentClick}
        checked_ids={queuesid}
        title="Queues"
        id="Queues"
        data={filterArray?.queues?.data?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.isQueuesFetch}
        showdropdown={showqueues}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={queuesName}
        selectAll={selectAll}
        view={view}
        tooltipTitle="Filter resources on their queues."
        isOpen={openDropdown === 'common-Queues'}
        toggleDropdown={toggleDropdown}
        filterType={"common"}
      ></DropDown>
      }
      {showskill === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={skillinputText}
        currentClick={currentClick}
        checked_ids={Skillid}
        title="Skills"
        id="Skills"
        data={filterArray?.skills?.data?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.isSkillFetch}
        showdropdown={showskill}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={SkillName}
        selectAll={selectAll}
        tooltipTitle="Filter data on their skills."
        isOpen={openDropdown === 'common-Skills'}
        toggleDropdown={toggleDropdown}
        filterType={"common"}
      ></DropDown>
      }
      {showlineOfBusiness === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={lineOfBusinessinputText}
        currentClick={currentClick}
        checked_ids={lineOfBusinessid}
        title="Line of business"
        id="LineOfBusiness"
        data={filterArray?.lineOfBusiness?.data?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.islineOfBusinessFetch}
        showdropdown={showlineOfBusiness}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={lineOfBusinessName}
        selectAll={selectAll}
        view={view}
        tooltipTitle="Filter data on their line of business."
        isOpen={openDropdown === 'common-LineOfBusiness'}
        toggleDropdown={toggleDropdown}
        filterType={"common"}
      ></DropDown>
      }
      
      {showprojectlead === "" &&
      <DropDown imgpath="/media/icons/duotune/new-icons/project-leads.svg" inputHandler={inputHandler} inputText={projectleadinputText} currentClick={currentClick} checked_ids={projectleadid} title="projectlead" data={filterArray?.peoples?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isPeopleFetch} showdropdown={showprojectlead} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={projectleadName} selectAll={selectAll} tooltipTitle='Filter data on their project leads.' isOpen={openDropdown === 'common-projectlead'} toggleDropdown={toggleDropdown} id="projectlead" filterType={"common"}></DropDown>
      }

      {showpriorities  === "" &&
        <DropDown imgpath="/media/icons/duotune/new-icons/priorities.svg" inputHandler={inputHandler} inputText={prioritiesinputText} currentClick={currentClick} checked_ids={prioritiesid} title="Priorities" data={filterArray?.priorities?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isPrioritiesFetch} showdropdown={showpriorities} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={prioritiesName} selectAll={selectAll} tooltipTitle='Filter data on their priorities.' isOpen={openDropdown === 'common-Priorities'} toggleDropdown={toggleDropdown} id="Priorities" filterType={"common"}></DropDown>
      }
      {showtickettype === "" &&
        <DropDown imgpath="/media/icons/duotune/new-icons/ticket-type.svg" inputHandler={inputHandler} inputText={tickettypeinputText} currentClick={currentClick} checked_ids={tickettypeid} title="Ticket type" data={filterArray?.ticketType?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isTicketTypeFetch} showdropdown={showtickettype} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={tickettypeName} selectAll={selectAll} tooltipTitle='Filter data on their ticket type.' isOpen={openDropdown === 'common-Ticket type'} toggleDropdown={toggleDropdown} id="Ticket type" filterType={"common"}></DropDown>
      }
      {showticketcategory === "" &&
        <DropDown imgpath="/media/icons/duotune/new-icons/ticket-category.svg" inputHandler={inputHandler} inputText={ticketcategoryinputText} currentClick={currentClick} checked_ids={ticketcategoryid} title="Ticket category" data={filterArray?.ticketCategory?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isTicketCategoryFetch} showdropdown={showticketcategory} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={ticketcategoryName} selectAll={selectAll} tooltipTitle='Filter data on their ticket category.' isOpen={openDropdown === 'common-Ticket category'} toggleDropdown={toggleDropdown} id="Ticket category" filterType={"common"}></DropDown>
      }
      {showtaskcategory === "" && 
        <DropDown imgpath="/media/icons/duotune/new-icons/task-category.svg" inputHandler={inputHandler} inputText={taskcategoryinputText} currentClick={currentClick} checked_ids={taskcategoryid} title="Task category" data={filterArray?.taskCategory?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isTaskCategoryFetch} showdropdown={showtaskcategory} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={taskcategoryName} selectAll={selectAll} tooltipTitle='Filter data on their task category.' isOpen={openDropdown === 'common-Task category'} toggleDropdown={toggleDropdown} id="Task category" filterType={"common"}></DropDown>
      }
      {showslas === "" &&
        <DropDown imgpath="/media/icons/duotune/new-icons/slas.svg" inputHandler={inputHandler} inputText={slasinputText} currentClick={currentClick} checked_ids={slasid} title="SLAs" data={filterArray?.slas?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isSlasFetch} showdropdown={showslas} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={slasName} selectAll={selectAll} tooltipTitle='Filter data on their slas.' isOpen={openDropdown === 'common-SLAs'} toggleDropdown={toggleDropdown} id="SLAs" filterType={"common"}></DropDown>
      }
      {showcontractcategory === "" && 
        <DropDown imgpath="/media/icons/duotune/new-icons/contract-category.svg" inputHandler={inputHandler} inputText={contractcategoryinputText} currentClick={currentClick} checked_ids={contractcategoryid} title="Contract category" data={filterArray?.contractCategory?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isContractCategoryFetch} showdropdown={showcontractcategory} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={contractcategoryName} selectAll={selectAll} tooltipTitle='Filter data on their contract category.' isOpen={openDropdown === 'common-Contract category'} toggleDropdown={toggleDropdown}  id="Contract category" filterType={"common"}></DropDown>
      }
      {showtaskticketstatus === "" && 
        <DropDown imgpath="/media/icons/duotune/new-icons/contract-category.svg" inputHandler={inputHandler} inputText={taskticketstatusinputText} currentClick={currentClick} checked_ids={taskticketstatusid} title="Task/Ticket status" data={filterArray?.taskticketstatus?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.iafetchstatus} showdropdown={showtaskticketstatus} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={taskticketstatusName} selectAll={selectAll} tooltipTitle='Filter data on their statuses.' isOpen={openDropdown === 'common-Statuses'} toggleDropdown={toggleDropdown}  id="Statuses" filterType={"common"}></DropDown>
      }
      {showcompany === "" && isCompanyVisable &&
          <DropDown  isCompanyVisable={isCompanyVisable} imgpath="/media/icons/duotune/extra/profile-user-1.svg" inputHandler={inputHandler} inputText={companyinputText} currentClick={currentClick} checked_ids={Companyid} title="Company"  data={filterArray?.companies?.filter((twork: any) => (twork.isActive === true))} isLoading={filterArray?.isCompanyFetch} showdropdown={showcompany} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={CompanyName} selectAll={selectAll} tooltipTitle='Filter resources on their company.' isOpen={openDropdown === 'common-Company'} id="Company" filterType={"common"}
          toggleDropdown={toggleDropdown}></DropDown>

      }
    </>
  );
};

export default CommonFilter;
