import moment from "moment-timezone";
export const hasOverlap = (args: any, inst: any, planningTask: any) => {
  const ev = args || {};
  let events = [];
  var resultData = planningTask?.filter(function (a: any) {
    return (
      (moment(ev.start).isSameOrAfter(a.start) &&
        moment(ev.start).isSameOrBefore(a.end)) ||
      (moment(ev.end).isSameOrAfter(a.start) &&
        moment(ev.end).isSameOrBefore(a.end))
    );
  });
  if (!inst) {
    events = resultData?.filter(
      (e: any) =>
        e.id !== ev.id &&
        ev?.servicecall_resources.includes(parseInt(e.resource))
    );
  } else {
    events = resultData?.filter(
      (e: any) => e.id !== ev.id && e.resource === ev.resource
    );
  }
  return events?.length > 0;
};
