import { lazy, FC, Suspense, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import PlanningPage from "../modules/apps/planning/PlanningPage";
import ProjectPage from "../modules/apps/project/ProjectPage";
import LogsPage from "../modules/apps/logs/LogsPage";
import DashboardPage from "../modules/apps/dashboard-management/DashboardPage";
import SettingPage from "../modules/apps/settings/SettingPage";
import ProfilePage from "../modules/apps/profile/ProfilePage";
import NotificationPage from "../modules/apps/notification/NotificationPage";
import RegistrationPage from "../modules/apps/registration/RegistrationPage";
import MyOrganisationPage from "../modules/apps/Myorganisation/MyOrganisationPage";
import { useAuth } from "../modules/auth/core/AuthCognito";
import PaymentDetails from "../modules/apps/PaymentDetails/PaymentDetails";
import ToastNotification from "../modules/apps/common/toast-notification";
import AISettings from "../modules/apps/ai-settings/AISettings";
import { useDispatch } from "react-redux";
import { setInitialSyncState, setOrganizationId } from "../InitialSyncToastSlice";
import EditProjectPage from "../modules/apps/project/EditProjectPage";
import ProjectBreakdown from "../modules/apps/project/ProjectBreakdown";
import BulkEditPage from "../modules/apps/project/BulkEditPage";
const InvoicePage = lazy(() => import("../modules/superadmin/invoices/InvoicePage"));
const PrivateRoutes = () => {
  const BillingSubscriptionPage = lazy(() => import("../modules/apps/billing-subscription/BillingSubscriptionPage"));

  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );
  const RolesPage = lazy(
    () => import("../modules/apps/role-management/RolesPage")
  );
  const CompaniesPage = lazy(
    () => import("../modules/apps/companies/CompaniesPage")
  );
  const EmailPage = lazy(() => import("../modules/apps/email/EmailPage"));
  const TasksPage = lazy(() => import("../modules/apps/kanban/KanbanPage"));
  const { currentUser } = useAuth();
  const dispatch = useDispatch();

  const LoadNotification =  () => !currentUser?.data?.user?.initial_sync_completed && <ToastNotification />

  useEffect(() => {
    const { initial_sync_completed, organizationId } = currentUser?.data?.user;
    dispatch(setOrganizationId(organizationId));
    dispatch(setInitialSyncState(initial_sync_completed));
  },[]);
  
  return (
    <>
        
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path="auth/*" element={<Navigate to="/dashboard" />} /> */}
         
        {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "Dashboard" && item.value === true)) ?
          <Route path="auth/*" element={<Navigate to="/apps/dashboard-management/dashboard" />} />
          :
          <Route path="apps/dashboard-management/dashboard" element={<Navigate to="/error/500" />} />
        }

        {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "Dashboard" && item.value === true)) ?
          <Route
            key={"dashboard"}
            path="apps/dashboard-management/dashboard"
            element={
              <SuspensedView>
               <LoadNotification />
                <DashboardPage />
              </SuspensedView>
            }
          />
          :
          <Route path="apps/dashboard-management/dashboard" element={<Navigate to="/error/500" />} />
        }

        {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "Users" && item.value === true)) ?
          <Route
            path="apps/user-management/*"
            element={
              <SuspensedView>
                <LoadNotification />
                <UsersPage />
              </SuspensedView>
            }
          />
          :
          <Route path="apps/user-management/*" element={<Navigate to="/error/500" />} />
        }
        <Route
          path="apps/role-management/*"
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/mail-template/*"
          element={
            <SuspensedView>
              <EmailPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/companies/*"
          element={
            <SuspensedView>
              <CompaniesPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/kanban/*"
          element={      
            <SuspensedView>
              <TasksPage />
            </SuspensedView>
          }
        />
        {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "Planning" && item.value === true)) ?
          <Route
            path="apps/planning/*"
            element={
              <SuspensedView>
                <LoadNotification />
                <PlanningPage />
              </SuspensedView>
            }
          />
          :
          <Route path="apps/planning/*" element={<Navigate to="/error/500" />} />
        }

         {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "Projects" && item.value === true)) ?
              <Route
                path="apps/projects/bulkedit-tasks"
                element={
                  <SuspensedView>
                <LoadNotification />
                <BulkEditPage />
              </SuspensedView>
            }
          />
          :
          <Route path="apps/projects/bulkedit-tasks" element={<Navigate to="/error/500" />} />
        }


        {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "Projects" && item.value === true)) ?
          <Route
            path="apps/projects/*"
            element={
              <SuspensedView>
                <LoadNotification />
                <ProjectPage />
              </SuspensedView>
            }
          />
          :
          <Route path="apps/projects/*" element={<Navigate to="/error/500" />} />
          }

          {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "Projects" && item.value === true)) ?
            <Route
              path="apps/projects/project-breakdown"
              element={
                <SuspensedView>
                  <LoadNotification />
                  <ProjectBreakdown />
                </SuspensedView>
              }
            />
            :
            <Route path="apps/projects/*" element={<Navigate to="/error/500" />} />
          }
        {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "Projects" && item.value === true)) ?
          <Route
            path="apps/projects/list/create-project"
            element={
              <SuspensedView>
                <LoadNotification />
                <EditProjectPage />
              </SuspensedView>
            }
          />
          :
          <Route path="apps/projects/list/create-project" element={<Navigate to="/error/500" />} />
        }
        {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "Projects" && item.value === true)) ?
          <Route
            path="apps/projects/list/edit/:id"
            element={
              <SuspensedView>
                <LoadNotification />
                <EditProjectPage />
              </SuspensedView>
            }
          />
          :
          <Route path="apps/projects/list/edit/:id" element={<Navigate to="/error/500" />} />
        }
        {/* <Route
          path="/apps/task/*"
          element={
            <SuspensedView>
              <TaskPage />
            </SuspensedView>
          }
        /> */}
        {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "Logs" && item.value === true)) ?
          <Route
            path="apps/logs/*"
            element={
              <SuspensedView>
                <LoadNotification />
                <LogsPage />
              </SuspensedView>
            }
          />
          :
          <Route path="apps/logs/*" element={<Navigate to="/error/500" />} />
        }
        {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "Setting" && item.value === true)) ?
          <Route
            path="/apps/settings/*"
            element={
              <SuspensedView>
                <LoadNotification />
                <SettingPage />
              </SuspensedView>
            }
          />
          :
          <Route path="/apps/settings/*" element={<Navigate to="/error/500" />} />
        }
        <Route
          path="/apps/profile/*"
          element={
            <SuspensedView>
              <LoadNotification />
              <ProfilePage />
            </SuspensedView>
          }
        />
        {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "Notification" && item.value === true)) ?
          <Route
            path="/apps/notifications/*"  
            element={
              <SuspensedView>
                <LoadNotification />
                <NotificationPage />
              </SuspensedView>
            }
          />
          :
          <Route path="/apps/notifications/*" element={<Navigate to="/error/500" />} />
        }
        <Route
          path="/register/*"
          element={
            <SuspensedView>
              <LoadNotification />
              <RegistrationPage />
            </SuspensedView>
          }
        />

        {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "AI settings" && item.value === true)) ?
          <Route
            path="/apps/ai-settings/*"
            element={
              <SuspensedView>
                <LoadNotification />
                <AISettings />
              </SuspensedView>
            }
          />
          :
          <Route path="/apps/ai-settings/*" element={<Navigate to="/error/500" />} />
        } 

        {currentUser?.data?.user?.permissions && (currentUser?.data?.user?.permissions?.some((item: any) => item.name === "My organisation" && item.value === true)) ?
          <Route
            path="/apps/organisation/*"
            element={
              <SuspensedView>
                <LoadNotification />
                <MyOrganisationPage />
              </SuspensedView>
            }
          />
          :
          <Route path="/apps/organisation/*" element={<Navigate to="/error/500" />} />
        } 

        { currentUser?.data?.user?.permissions &&
          currentUser?.data?.user?.permissions?.some(
            (item: any) => item.name === "Billing & Subscription" && item.value === true
          ) ? (
            <Route
              path="apps/billing-subscription/*"
              element={
                <SuspensedView>
                  <LoadNotification />
                  <BillingSubscriptionPage/>
                </SuspensedView>
              }
            />
          ) : (
            <Route path="apps/billing-subscription/*" element={<Navigate to="/error/500" />} />
        )}

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />

       {/* changes by nirbhay  */}

       {/* {currentUser?.data?.user?.type == "admin" ?
   <Route
   path="invoice-settings/*"
   element={
    <SuspensedView>
       <InvoiceSetting />

    </SuspensedView>
   }
 />
       :
       <Route path="invoice-settings/*" element={<Navigate to="/error/404" />} />
      }  */}

{currentUser?.data?.user?.type == "admin" ?
  <Route
  path="invoices-management/*"
  element={
    <SuspensedView>
      <InvoicePage />
    </SuspensedView>
  }
/>   :  <Route path="invoices-management/*" element={<Navigate to="/error/404" />} />
      }

{/* payment details  */}
{currentUser?.data?.user?.type == "admin" ?
  <Route
  path="payment/*"
  element={
    <SuspensedView>
      <PaymentDetails/>
    </SuspensedView>
  }
/>   :  <Route path="invoices-management/*" element={<Navigate to="/error/404" />} />
      }


    </Route>
    </Routes>
    </>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
